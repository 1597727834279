import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Box, CircularProgress, Container } from "@mui/material";
import { DJSimpleCodeBlock, ICodeBlock } from "../main/components/codeblock";
import { useLocation, useNavigate } from "react-router-dom";
import { ISessionProps, SessionStatus } from "../main/components/IProgress";
import { getGCD } from "../services/api";
import transition from "../main/components/transition";
import { Note, Typography } from "@appsflyer/fe-ui-core";
import {
  amplitudeTrack_continue,
  amplitudeTrack_getResults,
  sendGtagOnce,
} from "../services/utils";
import AmplitudeLink from "../services/AmplitudeLink";

const TestResultManual = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [passed, setPassed] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [retriesCounter, setRetriesCounter] = useState(0);

  useEffect(() => {
    getResults();
  }, []);

  const getResults = () => {
    setTimeout(() => {
      let isPassed = false;
      getGCD(location.state?.installUID)
        ?.then((res) => {
          if (res?.status == "Success") {
            setPassed(true);
            isPassed = true;
            setData(res.data);
            const newS = setNewState(isPassed, res.data);

            navigate(
              `/${location.state.path}/${location.state.os}/testresults`,
              {
                replace: true,
                state: newS,
              }
            );
            // send gtag for manual testing success
            sendGtagOnce(
              "manual_test_success_fixed",
              "TestResultPassed",
              location.state.sessionId
            );
          } else {
            setPassed(false);
            isPassed = false;
            setData(res.data);
            const newS = setNewState(isPassed, res.data);

            navigate(
              `/${location.state.path}/${location.state.os}/testresults`,
              {
                replace: true,
                state: newS,
              }
            );
          }
          console.log(res);
        })
        .catch((err) => {
          setPassed(false);
          console.error(err);
          const newS = setNewState(isPassed, err);

          navigate(`/${location.state.path}/${location.state.os}/testresults`, {
            replace: true,
            state: newS,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }, 6000);
  };

  const refreshResults = () => {
    setRetriesCounter(retriesCounter + 1);
    setLoading(true);
    getResults();
  };

  function setNewState(isPassed, data): ISessionProps {
    const progressState: ISessionProps = {
      ...location.state,
      currentPage: location.state?.currentPage + 1,
      completionStatus: isPassed,
      sessionStatus: isPassed ? SessionStatus.Success : SessionStatus.Failed,
      retriesCounter: retriesCounter,
      completionResult: { gcdData: data },
    };

    amplitudeTrack_getResults(progressState);

    return progressState;
  }

  function finishProcess() {
    const progressState: ISessionProps = setNewState(passed, data);
    amplitudeTrack_continue(progressState);
    amplitudeTrack_getResults(progressState);
    navigate("/finish", { state: progressState });
  }

  const codeProps: ICodeBlock = {
    codePrimary: JSON.stringify(data, null, 2),
    language: "swift",
    showLineNumbers: true,
  };

  return (
    <Container maxWidth={"lg"}>
      {loading ? (
        <Box
          textAlign="center"
          padding={3}
          marginTop={2}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"700px"}
        >
          <CircularProgress />
        </Box>
      ) : passed ? (
        <>
          <Box padding={3} style={{ minHeight: "50vh" }}>
            <Typography variant="h1">
              {t("testConsole_TestResults_TestResults")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              bgcolor={"#258750"}
              color={"white"}
              width={100}
              height={30}
              mt={5}
              mb={2}
              fontWeight={"bold"}
            >
              {t("testConsole_TestResults_Passed")}
            </Box>
            <Typography style={{ margin: "5px 0 " }}>
              {t("testConsole_TestResults_ReceivedAttributionData")}
            </Typography>
            <DJSimpleCodeBlock {...codeProps}></DJSimpleCodeBlock>
          </Box>
          <Box
            display={"flex"}
            justifyContent="space-between"
            alignSelf="end"
            justifySelf="end"
            marginTop={10}
            textAlign="end"
          >
            {" "}
            <Button
              variant="outlined"
              size="medium"
              color="secondary"
              style={{ marginRight: "5px" }}
              onClick={() => navigate(-3)}
            >
              {t("testConsole_TestResults_RunTestsAgain")}
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={() => finishProcess()}
            >
              {t("testConsole_TestResults_Exit")}
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box padding={3} style={{ minHeight: "50vh" }}>
            <Typography variant="h1">
              {t("testConsole_TestResults_TestResults")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              bgcolor={"#D83A52"}
              color={"white"}
              width={100}
              height={30}
              my={4}
              fontWeight={"bold"}
            >
              {t("testConsole_TestResults_Failed")}
            </Box>
            <DJSimpleCodeBlock {...codeProps}></DJSimpleCodeBlock>
            <Box mt={2} width={400} padding={1}>
              <Typography variant={"h3"} mt={5}>
                {t("testConsole_TestResults_TroubleshootingTips")}
              </Typography>
              <Box width={400} padding={1} mt={1}>
                <Box mb={2}>
                  <Note>
                    {t("testConsole_TestType_idfa_li1_a")}:
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      onClick={() => navigate(-4)}
                    >
                      {t("progress_startsdk_startSDK")}
                    </Button>
                  </Note>
                </Box>
                <Note>
                  {t("testConsole_TestResults_RunTipsHere")}
                  <AmplitudeLink
                    ml={0.5}
                    mr={0.5}
                    target="_blank"
                    href={`https://${
                      location.state.lang != "en"
                        ? location.state.lang + "."
                        : ""
                    }dev.appsflyer.com/hc/docs/testing-android#troubleshooting-the-android-sdk-integration`}
                  >
                    {t("testConsole_TestResults_TroubleshootingSection")}
                  </AmplitudeLink>
                  {t("testConsole_TestResults_InDevHub")}
                </Note>
              </Box>
            </Box>
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => refreshResults()}
                disabled={loading}
                size="medium"
                style={{ marginRight: "5px" }}
              >
                {t("StartSDK_testSDK_ios_testResult_runTestAgain")}
              </Button>
            </Box>
          </Box>
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent="space-between"
            alignSelf="end"
            justifySelf="end"
            marginTop={10}
            textAlign="end"
          >
            {" "}
            <Button
              variant="outlined"
              size="medium"
              color="secondary"
              style={{ marginRight: "5px" }}
              onClick={() => navigate(-3)}
            >
              {t("testConsole_TestResults_RunTestsAgain")}
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={() => finishProcess()}
            >
              {t("testConsole_TestResults_Exit")}
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default transition(TestResultManual);
