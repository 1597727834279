import { useEffect, useState } from "react";
import { Box, Container, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DJCodeBlock, ICodeBlock } from "../../main/components/codeblock";
import { useLocation, useNavigate } from "react-router-dom";
import { IProgress, ISessionProps } from "../../main/components/IProgress";
import { Typography } from "@appsflyer/fe-ui-core";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import transition from "../../main/components/transition";
import { amplitudeTrack_continue } from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";

const DL_implementCodeIOS = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [progress, setProgress] = useState<IProgress[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [extractedDomain, setExtractedDomain] = useState("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (location.state) {
      const state: ISessionProps = location.state as ISessionProps;
      setProgress(state?.progress);
    }
    const onelink: URL = new URL(
      location.state.oneLinkURL.indexOf("http") > -1
        ? location.state.oneLinkURL
        : "https://" + location.state.oneLinkURL
    );
    setExtractedDomain(onelink.host);
  }, [location.state]);

  const handleClick = () => {
    const progressArr: IProgress[] = progress.slice();
    progressArr.push({
      id: "dl_dltype",
      text: "progress_DL_type",
      order: 4,
    });
    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    };
    amplitudeTrack_continue(location.state);

    navigate("/deeplink/ios/deeplinktype", { state: progressState });
  };

  const codeProps: ICodeBlock[] = [
    {
      codePrimary: `import AppsFlyerLib;`,
      codeSecondary: `#import <AppsFlyerLib/AppsFlyerLib.h>`,
      showLineNumbers: true,
      highlight: "1",
    },
    {
      codePrimary: `@UIApplicationMain
class AppDelegate: UIResponder, UIApplicationDelegate {
  //...
  func application(_ application: UIApplication, continue userActivity: NSUserActivity, restorationHandler: @escaping 
  ([UIUserActivityRestoring]?) -> Void) -> Bool {
    AppsFlyerLib.shared().continue(userActivity, restorationHandler: nil)
    return true
  } 
}`,
      codeSecondary: `- (BOOL)application:(UIApplication *)application continueUserActivity:(NSUserActivity *)userActivity restorationHandler:(void (^)(NSArray * _Nullable))restorationHandler {
    [[AppsFlyerLib shared] continueUserActivity:userActivity restorationHandler:nil];
    return YES;
}`,
      showLineNumbers: true,
      highlight: "4-8",
    },
    {
      codePrimary: `@UIApplicationMain
class AppDelegate: UIResponder, UIApplicationDelegate {
  //...
  func application(_ app: UIApplication, open url: URL, options: [UIApplication.OpenURLOptionsKey : Any] = [:]) -> Bool 
  {
      AppsFlyerLib.shared().handleOpen(url, options: options)
      return true
  }
  //...   
}`,
      codeSecondary: `- (BOOL)application:(UIApplication *)app openURL:(NSURL *)url options:(NSDictionary<UIApplicationOpenURLOptionsKey,id> *)options {
    [[AppsFlyerLib shared] handleOpenUrl:url options:options];
    return YES;
}`,
      showLineNumbers: true,
      highlight: "4-8",
    },
    {
      codePrimary: `class SceneDelegate: UIResponder, UIWindowSceneDelegate {
  var window: UIWindow?
          
  func scene(_ scene: UIScene, continue userActivity: NSUserActivity) {
      // Universal Link - Background -> foreground
      AppsFlyerLib.shared().continue(userActivity, restorationHandler: nil)
  }
  
  func scene(_ scene: UIScene, openURLContexts URLContexts: Set<UIOpenURLContext>) {
      // URI scheme - Background -> foreground
      if let url = URLContexts.first?.url {
          AppsFlyerLib.shared().handleOpen(url, options: nil)
      }
  } 

  func scene(_ scene: UIScene, willConnectTo session: UISceneSession, options connectionOptions: UIScene.ConnectionOptions) {
      
      // URI scheme - killed -> foreground
      
      // Use this method to optionally configure and attach the UIWindow 'window' to the provided UIWindowScene 'scene'.
      // If using a storyboard, the 'window' property will automatically be initialized and attached to the scene.
      // This delegate does not imply the connecting scene or session are new (see 'application:configurationForConnectingSceneSession' instead).

      // Processing Universal Link from the killed state
      if let userActivity = connectionOptions.userActivities.first {
          AppsFlyerLib.shared().continue(userActivity, restorationHandler: nil)
      } else if let url = connectionOptions.urlContexts.first?.url {
          AppsFlyerLib.shared().handleOpen(url, options: nil)
      }
      guard let _ = (scene as? UIWindowScene) else { return }
  }
}`,
      codeSecondary: `- (void)scene:(UIScene *)scene willConnectToSession:(UISceneSession *)session options:(UISceneConnectionOptions *)connectionOptions {
        // Use this method to optionally configure and attach the UIWindow 'window' to the provided UIWindowScene 'scene'.
        // If using a storyboard, the 'window' property will automatically be initialized and attached to the scene.
        // This delegate does not imply the connecting scene or session are new (see 'application:configurationForConnectingSceneSession' instead).
        
        // Processing Universal Link from the killed state
        NSUserActivity *userActivity = connectionOptions.userActivities.anyObject;
        if (userActivity) {
            [[AppsFlyerLib shared] continueUserActivity:userActivity restorationHandler:nil];
        } else {
            UIOpenURLContext *urlContext = connectionOptions.URLContexts.anyObject;
            if (urlContext) {
                NSURL *url = urlContext.URL;
                [[AppsFlyerLib shared] handleOpenUrl:url options:nil];
            }
        }
        
        // Use this method to optionally configure and attach the UIWindow 'window' to the provided UIWindowScene 'scene'.
        // Ensure the window property is initialized and attached if needed.
        if (![scene isKindOfClass:[UIWindowScene class]]) {
            return;
        }
    }

- (void)scene:(UIScene *)scene continueUserActivity:(NSUserActivity *)userActivity {
        // Universal Link - Background -> foreground
        [[AppsFlyerLib shared] continueUserActivity:userActivity restorationHandler:nil];
    }

- (void)scene:(UIScene *)scene openURLContexts:(NSSet<UIOpenURLContext *> *)URLContexts {
        // URI scheme - Background -> foreground
        UIOpenURLContext *urlContext = URLContexts.anyObject;
        if (urlContext) {
            NSURL *url = urlContext.URL;
            [[AppsFlyerLib shared] handleOpenUrl:url options:nil];
        }
    }`,
      showLineNumbers: true,
      highlight: "6,11-13,25-29",
    },
    {
      codePrimary: `extension AppDelegate: DeepLinkDelegate {
  // We fill this context in the following stages
}`,
      codeSecondary: `#pragma mark - DeepLinkDelegate
// We fill this context in the following stages`,
      showLineNumbers: true,
    },
    {
      codePrimary: `class AppDelegate: UIResponder, UIApplicationDelegate {
  var window: UIWindow?
  func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: 
    [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
    //...
      AppsFlyerLib.shared().deepLinkDelegate = self
      //...
  } 
}`,
      codeSecondary: `- (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {
          //...        
          [AppsFlyerLib shared].deepLinkDelegate = self;    
          //...
          return YES;
    }`,
      showLineNumbers: true,
      highlight: "6",
    },
    {
      codePrimary: `extension AppDelegate: DeepLinkDelegate {   
  func didResolveDeepLink(_ result: DeepLinkResult) {
    // We will fill this function in the following steps
  }
}`,
      codeSecondary: `#pragma mark - DeepLinkDelegate
- (void)didResolveDeepLink:(AppsFlyerDeepLinkResult *)result {
   NSLog(@"[AFSDK] Deep link flow");
    switch (result.status) {
        case AFSDKDeepLinkResultStatusNotFound:
            NSLog(@"[AFSDK] Deep link not found");
            return;
        case AFSDKDeepLinkResultStatusFailure:
            NSLog(@"Error %@", result.error);
            return;
        case AFSDKDeepLinkResultStatusFound:
            NSLog(@"[AFSDK] Deep link found");
            break;
    }    
}`,
      showLineNumbers: true,
      highlight: "2-4",
      highlightSecondary: "2-9,10-15"
    },
    {
      codePrimary: `extension AppDelegate: DeepLinkDelegate {
  func didResolveDeepLink(_ result: DeepLinkResult) {
      switch result.status {
          case .notFound:
              NSLog("[AFSDK] Deep link not found")
              return
          case .failure:
              print("Error %@", result.error!)
              return
          case .found:
              NSLog("[AFSDK] Deep link found")
      }

      guard let deepLinkObj:DeepLink = result.deepLink else {
          NSLog("[AFSDK] Could not extract deep link object")
          return
      }
  }
}`,
      codeSecondary: `#pragma mark - DeepLinkDelegate
- (void)didResolveDeepLink:(AppsFlyerDeepLinkResult *)result {
   NSLog(@"[AFSDK] Deep link flow");
    switch (result.status) {
        case AFSDKDeepLinkResultStatusNotFound:
            NSLog(@"[AFSDK] Deep link not found");
            return;
        case AFSDKDeepLinkResultStatusFailure:
            NSLog(@"Error %@", result.error);
            return;
        case AFSDKDeepLinkResultStatusFound:
            NSLog(@"[AFSDK] Deep link found");
            break;
    }    
    AppsFlyerDeepLink *deepLinkObj = result.deepLink;
}`,
      showLineNumbers: true,
      highlight: "2-9,10-17",
      highlightSecondary: "15"
    },
    {
      codePrimary: `extension AppDelegate: DeepLinkDelegate {
  func didResolveDeepLink(_ result: DeepLinkResult) {
        switch result.status {
        case .notFound:
            NSLog("[AFSDK] Deep link not found")
            return
        case .failure:
            print("Error %@", result.error!)
            return
        case .found:
            NSLog("[AFSDK] Deep link found")
        }
        guard let deepLinkObj:DeepLink = result.deepLink else {
            NSLog("[AFSDK] Could not extract deep link object")
            return
        }
        if( deepLinkObj.isDeferred == true) {
            NSLog("[AFSDK] This is a deferred deep link")
        }
        else {
            NSLog("[AFSDK] This is a direct deep link")
        }
    }
}`,
      codeSecondary: `#pragma mark - DeepLinkDelegate
- (void)didResolveDeepLink:(AppsFlyerDeepLinkResult *)result {
   NSLog(@"[AFSDK] Deep link flow");
    switch (result.status) {
        case AFSDKDeepLinkResultStatusNotFound:
            NSLog(@"[AFSDK] Deep link not found");
            return;
        case AFSDKDeepLinkResultStatusFailure:
            NSLog(@"Error %@", result.error);
            return;
        case AFSDKDeepLinkResultStatusFound:
            NSLog(@"[AFSDK] Deep link found");
            break;
    }    
    AppsFlyerDeepLink *deepLinkObj = result.deepLink;
    
    if (deepLinkObj.isDeferred) {
        NSLog(@"[AFSDK] This is a deferred deep link");        
        } else {
        NSLog(@"[AFSDK] This is a direct deep link");
    }
}`,
      showLineNumbers: true,
      highlight: "17-22",
      highlightSecondary: "17-21"
    },
    {
      codePrimary: `extension AppDelegate: DeepLinkDelegate {
  func didResolveDeepLink(_ result: DeepLinkResult) {
        switch result.status {
        case .notFound:
            NSLog("[AFSDK] Deep link not found")
            return
        case .failure:
            print("Error %@", result.error!)
            return
        case .found:
            NSLog("[AFSDK] Deep link found")
        }
        guard let deepLinkObj:DeepLink = result.deepLink else {
            NSLog("[AFSDK] Could not extract deep link object")
            return
        }
        if( deepLinkObj.isDeferred == true) {
            NSLog("[AFSDK] This is a deferred deep link")
        }
        else {
            NSLog("[AFSDK] This is a direct deep link")
        }
        fruitNameStr = deepLinkObj.deeplinkValue  
    }
}`,
      codeSecondary: `#pragma mark - DeepLinkDelegate
- (void)didResolveDeepLink:(AppsFlyerDeepLinkResult *)result {
   NSLog(@"[AFSDK] Deep link flow");
    switch (result.status) {
        case AFSDKDeepLinkResultStatusNotFound:
            NSLog(@"[AFSDK] Deep link not found");
            return;
        case AFSDKDeepLinkResultStatusFailure:
            NSLog(@"Error %@", result.error);
            return;
        case AFSDKDeepLinkResultStatusFound:
            NSLog(@"[AFSDK] Deep link found");
            break;
    }    
    AppsFlyerDeepLink *deepLinkObj = result.deepLink;
    
    if (deepLinkObj.isDeferred) {
        NSLog(@"[AFSDK] This is a deferred deep link");        
        } else {
        NSLog(@"[AFSDK] This is a direct deep link");
    }

    inappDestinationStr = deepLinkObj.deeplinkValue;
}`,
      showLineNumbers: true,
      highlight: "23",
      highlightSecondary: "23"
    },
  ];

  const steps = [
    {
      label: t("dl_8_ios_step_a"),
      component: (
        <>
          <Typography>{t("dl_8_ios_make_sure_library_imported")}</Typography>
          <DJCodeBlock {...codeProps[0]}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_9_ios_step_b"),
      component: (
        <>
          <Typography variant="body1">
            {t("dl_9_ios_step_2_description")}
            <code> application() </code>
            {t("dl_9_ios_step_2_description_b")}
            <code> userActivity </code>
            {t("dl_9_ios_step_2_description_c")}
            <code>
              {" "}
              AppsFlyerLib.shared().continue(userActivity, restorationHandler:
              nil)
            </code>
          </Typography>
          <DJCodeBlock {...codeProps[1]}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_10_ios_step_c"),
      component: <DJCodeBlock {...codeProps[2]}></DJCodeBlock>,
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_11_ios_step_d"),
      component: <DJCodeBlock {...codeProps[3]}></DJCodeBlock>,
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_12_ios_step_e"),
      component: (
        <>
          <ul>
            <li>
              <AmplitudeLink
                href={`https://${
                  location.state.lang != "en" ? location.state.lang + "." : ""
                }dev.appsflyer.com/hc/docs/deeplinkdelegate`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("dl_12_ios_deep_link_delegate")}
              </AmplitudeLink>{" "}
              {t("dl_12_ios_deep_link_ext")} <code>AppDelegate</code>.
            </li>
            <li>
              <AmplitudeLink
                href={`https://${
                  location.state.lang != "en" ? location.state.lang + "." : ""
                }dev.appsflyer.com/hc/docs/deeplinkdelegate`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("dl_12_ios_deep_link_delegate")}
              </AmplitudeLink>{" "}
              {t("dl_12_ios_deep_link_holds")}
            </li>
          </ul>
          <DJCodeBlock {...codeProps[4]}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_13_ios_step_f"),
      component: (
        <>
          <DJCodeBlock {...codeProps[5]}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_14_ios_step_g"),
      component: (
        <>
          <Typography variant="body1">
            <AmplitudeLink
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/deeplinkdelegate#didresolvedeeplink`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <code>{t("dl_14_ios_did_resolve_deep_link")}</code>
            </AmplitudeLink>
            &nbsp;{t("dl_14_ios_did_resolve_deep_link_isCalled")}
          </Typography>
          <Typography variant="body1">
            {t("dl_14_ios_did_resolve_deep_link_summ")}&nbsp;
            <AmplitudeLink
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/deeplinkresult-1"`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <code>{t("dl_14_ios_deep_link_result")}</code>
            </AmplitudeLink>
            &nbsp;{t("dl_14_ios_deep_link_obj")}&nbsp;
            <AmplitudeLink
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/deeplinkdelegate#didresolvedeeplink`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <code>{t("dl_14_ios_did_resolve_deep_link")}</code>
            </AmplitudeLink>
          </Typography>
          <DJCodeBlock {...codeProps[6]}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_15_ios_step_g"),
      component: (
        <>
          <Typography variant="body1">
            {t("dl_15_ios_deep_link_link_use")}
            <AmplitudeLink
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/deeplinkresult-1#deeplink"`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <code>{t("dl_15_ios_deep_link_link")}</code>
            </AmplitudeLink>
            &nbsp;{t("dl_15_ios_deep_link_field")}&nbsp;
            <AmplitudeLink
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/deeplinkresult-1`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <code>{t("dl_15_ios_deep_link_result_link")}</code>
            </AmplitudeLink>
            &nbsp;{t("dl_15_ios_deep_link_result_retr")}&nbsp;
            <AmplitudeLink
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/deeplinkresult-1#deeplink`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <code>{t("dl_15_ios_deep_link_link")}</code>
            </AmplitudeLink>
            &nbsp;{t("dl_15_ios_deep_link_linkObj")}
          </Typography>
          <DJCodeBlock {...codeProps[7]} scrollPosition= {18}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_16_ios_step_h"),
      component: (
        <>
          <Typography>
            <AmplitudeLink
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/deeplink-1#isdeferred`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <code>{t("dl_16_ios_isDeferred_link")}</code>
            </AmplitudeLink>
            {" " + t("dl_15_ios_deep_link_field") + " "}
            <AmplitudeLink
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/deeplink-1`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <code>{t("dl_16_ios_DeepLink_link")}</code>
            </AmplitudeLink>
            &nbsp;{t("dl_16_ios_DeepLink_sign")}
          </Typography>
          <DJCodeBlock {...codeProps[8]} scrollPosition= "bottom"></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_17_ios_step_h"),
      component: (
        <>
          <Typography>
            {t("dl_17_ios_step_8_desc")}&nbsp;
            <AmplitudeLink
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/deeplink-1#deeplinkvalue`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <code>deeplinkValue</code>
            </AmplitudeLink>
            &nbsp;{t("dl_15_ios_deep_link_field")}&nbsp;
            <AmplitudeLink
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/deeplink-1`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <code>DeepLink</code>
            </AmplitudeLink>
            &nbsp;{t("dl_15_ios_deep_link_holds")} <code>deep_link_value</code>
          </Typography>
          <DJCodeBlock {...codeProps[9]} scrollPosition= "bottom"></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  // add branded domain step only if URL does not include onelink.me
  if (extractedDomain.indexOf("onelink.me") == -1) {
    const code_branded = `class AppDelegate: UIResponder, UIApplicationDelegate {
  //...
  func application(_ app: UIApplication, open url: URL, options: [UIApplication.OpenURLOptionsKey : Any] = [:]) -> Bool 
  {
      AppsFlyerLib.shared().handleOpen(url, options: options)
      AppsFlyerLib.shared().oneLinkCustomDomains = ["${extractedDomain}"]
      return true
  }
  //...   
}`;

    const codeBlockProps_branded: ICodeBlock = {
      codePrimary: code_branded,
      codeSecondary: `[AppsFlyerLib shared].oneLinkCustomDomains = @[@"${extractedDomain}"];`,
      showLineNumbers: true,
      highlight: "6",
    };
    steps.splice(3, 0, {
      label: t("dl_brandedDomains_label"),
      component: (
        <>
          {/* Use DJCodeBlock for code formatting */}
          <DJCodeBlock {...codeBlockProps_branded} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    });
  }

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} marginBottom={-10} style={{ minHeight: "50vh" }}>
        <Box marginBottom={2}>
          <Typography variant="h1">{t("dl_8_ios_import_libraries")}</Typography>
        </Box>

        <Stepper
          activeStep={activeStep}
          style={{ border: "none" }}
          orientation="vertical"
        >
          {steps.map(({ label, component, shouldDisplayNavigationButtons }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {component}
                {shouldDisplayNavigationButtons && (
                  <Box sx={{ mt: 2 }}>
                    {activeStep !== 0 && (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleBack}
                        // sx={{ mt: 2, mr: 1 }}
                      >
                        {t("general_Back")}
                      </Button>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        // sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1
                          ? t("general_Finish")
                          : t("general_Next")}
                      </Button>
                    )}
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("StartSDK_back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("StartSDK_continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(DL_implementCodeIOS);
