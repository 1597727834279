import { useEffect, useState } from "react";
import { Box, Container, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DJCodeBlock, ICodeBlock } from "../../main/components/codeblock";
import { IProgress, ISessionProps } from "../../main/components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@appsflyer/fe-ui-core";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import transition from "../../main/components/transition";
import { amplitudeTrack_continue } from "../../services/utils";

const DL_implementAndroid = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [progress, setProgress] = useState<IProgress[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [extractedDomain, setExtractedDomain] = useState("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (location.state) {
      const state: ISessionProps = location.state as ISessionProps;
      setProgress(state?.progress);
    }
    const onelink: URL = new URL(
      location.state.oneLinkURL.indexOf("http") > -1
        ? location.state.oneLinkURL
        : "https://" + location.state.oneLinkURL
    );
    setExtractedDomain(onelink.host);
  }, [location.state]);

  const handleClick = () => {
    const progressArr: IProgress[] = progress.slice();
    progressArr.push({
      id: "dl_dltype",
      text: "progress_DL_type",
      order: 4,
    });
    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    };
    amplitudeTrack_continue(location.state);

    navigate("/deeplink/android/deeplinktype", { state: progressState });
  };

  const code_implementJava = `import com.appsflyer.deeplink.DeepLink;
import com.appsflyer.deeplink.DeepLinkListener;
import com.appsflyer.deeplink.DeepLinkResult;`;

  const code_implementKotlin = `import com.appsflyer.deeplink.DeepLink
import com.appsflyer.deeplink.DeepLinkListener
import com.appsflyer.deeplink.DeepLinkResult`;

  const codeBlockProps_implement: ICodeBlock = {
    codePrimary: code_implementJava,
    codeSecondary: code_implementKotlin,
    showLineNumbers: true,
    highlight: "1-3",
    highlightSecondary: "1-3",
  };

  const code5Java = `public class ExampleApp extends application {
    @Override
    public void onCreate() {
      super.onCreate();
      ...
      appsflyer.subscribeForDeepLink(new DeepLinkListener(){
        // TODO - will be implemented in the following steps 
      }
      ...   
    }
 }`;

  const code5Kotlin = `class AppsflyerBasicApp: Application(){
  override fun onCreate() {
      super.onCreate()
      //Deep Linking Handling
          AppsFlyerLib.getInstance().subscribeForDeepLink(object : DeepLinkListener {
          // TODO - will be implemented in the following steps 
          }
  }
}`;

  const codeBlockProps5: ICodeBlock = {
    codePrimary: code5Java,
    codeSecondary: code5Kotlin,
    showLineNumbers: true,
    highlight: "6-8",
    highlightSecondary: "5-7",
  };

  const code6Java = `public class ExampleApp extends application {
    @Override
    public void onCreate() {
      super.onCreate();
      ...
      appsflyer.subscribeForDeepLink(new DeepLinkListener(){
         @Override
         public void onDeepLinking(@NonNull DeepLinkResult deepLinkResult) {
           // TODO - will be implemented in the following steps.
         }
      }
      ...   
    }
 }`;

 const code6Kotlin = `class AppsflyerBasicApp: Application(){
override fun onCreate() {
    super.onCreate()
    //Deep Linking Handling
        AppsFlyerLib.getInstance().subscribeForDeepLink(object : DeepLinkListener {
        // TODO - will be implemented in the following steps 
        }
    }
}`;

  const codeBlockProps6: ICodeBlock = {
    codePrimary: code6Java,
    codeSecondary: code6Kotlin,
    showLineNumbers: true,
    highlight: "7-9,10",
    highlightSecondary: "5-7"
  };

  const code7Java = `public class ExampleApp extends application {
    @Override
    public void onCreate() {
      super.onCreate();
      ...
      appsflyer.subscribeForDeepLink(new DeepLinkListener(){
         @Override
         public void onDeepLinking(@NonNull DeepLinkResult deepLinkResult) {
             DeepLinkResult.Status dlStatus = deepLinkResult.getStatus();
             if (dlStatus == DeepLinkResult.Status.NOT_FOUND) {
                 Log.d(LOG_TAG, "Deep link not found");
                 return;
             } else if (dlStatus ==  DeepLinkResult.Status.ERROR){
                 DeepLinkResult.Error dlError = deepLinkResult.getError();
                 Log.d(LOG_TAG, "There was an error getting Deep Link data: " + dlError.toString());
                 return;
             } else {
                 // dlStatus == DeepLinkResult.Status.FOUND) {
                 Log.d(LOG_TAG, "Deep link found");
             }
             // TODO - will be implemented in the following steps.
         }
      }
 }`;

 const code7Kotlin = `class AppsflyerBasicApp: Application(){
	override fun onCreate() {
     super.onCreate()
     //Deep Linking Handling
        AppsFlyerLib.getInstance().subscribeForDeepLink(object : DeepLinkListener {
	        DeepLinkResult.Status.NOT_FOUND -> {
	            Log.d(LOG_TAG, "Deep link not found")
	            return
	        }
	        DeepLinkResult.Status.ERROR -> {
	            // dlStatus == 
	            val dlError = deepLinkResult.error
	            Log.d(LOG_TAG, "There was an error getting Deep Link data: $dlError")
	            return
	        }
	        else -> {
	            // DeepLinkResult.Status.FOUND  
	            Log.d(LOG_TAG, "Deep link found")
	        }
	        // TODO - will be implemented in the following steps 
        }        
  }
}`;

  const codeBlockProps7: ICodeBlock = {
    codePrimary: code7Java,
    codeSecondary: code7Kotlin,
    showLineNumbers: true,
    highlight: "9,10-21",
    highlightSecondary: "6-9,10-20",
    scrollPosition: 95,
  };

  const code8Java = `public class ExampleApp extends application {
    @Override
    public void onCreate() {
      super.onCreate();
      ...
      appsflyer.subscribeForDeepLink(new DeepLinkListener(){
         @Override
         public void onDeepLinking(@NonNull DeepLinkResult deepLinkResult) {
             DeepLinkResult.Status dlStatus = deepLinkResult.getStatus();
             if (dlStatus == DeepLinkResult.Status.NOT_FOUND) {
                 Log.d(LOG_TAG, "Deep link not found");
                 return;
             } else if (dlStatus ==  DeepLinkResult.Status.ERROR){
                 DeepLinkResult.Error dlError = deepLinkResult.getError();
                 Log.d(LOG_TAG, "There was an error getting Deep Link data: " + dlError.toString());
                 return;
             } else {
                 // dlStatus == DeepLinkResult.Status.FOUND) {
                 Log.d(LOG_TAG, "Deep link found");
             }
             DeepLink deepLinkObj = deepLinkResult.getDeepLink();
             try {
                 Log.d(LOG_TAG, "The DeepLink data is: " + deepLinkObj.toString());
                 // Your code for handling the deeplink data
             } catch (Exception e) {
                 Log.d(LOG_TAG, "DeepLink data came back null");
                  return;
             }
         }
      }
 }`;

 const code8Kotlin = `class AppsflyerBasicApp: Application(){
	override fun onCreate() {
     super.onCreate()
     //Deep Linking Handling
        AppsFlyerLib.getInstance().subscribeForDeepLink(object : DeepLinkListener {
            DeepLinkResult.Status.NOT_FOUND -> {
                Log.d(LOG_TAG, "Deep link not found")
                return
            }
            DeepLinkResult.Status.ERROR -> {
                // dlStatus == 
                val dlError = deepLinkResult.error
                Log.d(LOG_TAG, "There was an error getting Deep Link data: $dlError")
                return
            }
            else -> {
                // DeepLinkResult.Status.FOUND  
                Log.d(LOG_TAG, "Deep link found")
            }
            val deepLinkObj: DeepLink = deepLinkResult.deepLink
            try {
                Log.d(LOG_TAG, "The DeepLink data is: $deepLinkObj")
                 // Your code for handling the deeplink data
            } catch (e: Exception) {
                Log.d(LOG_TAG, "DeepLink data came back null")
                return
            } 
        }        
  }
}`;

  const codeBlockProps8: ICodeBlock = {
    codePrimary: code8Java,
    codeSecondary: code8Kotlin,
    showLineNumbers: true,
    highlight: "21-28",
    highlightSecondary: "20-27",
    scrollPosition: "bottom",
  };

  const code9Java = `public class ExampleApp extends application {
    @Override
    public void onCreate() {
      super.onCreate();
      ...
      appsflyer.subscribeForDeepLink(new DeepLinkListener(){
         @Override
         public void onDeepLinking(@NonNull DeepLinkResult deepLinkResult) {
             DeepLinkResult.Status dlStatus = deepLinkResult.getStatus();
             if (dlStatus == DeepLinkResult.Status.NOT_FOUND) {
                 Log.d(LOG_TAG, "Deep link not found");
                 return;
             } else if (dlStatus ==  DeepLinkResult.Status.ERROR){
                 DeepLinkResult.Error dlError = deepLinkResult.getError();
                 Log.d(LOG_TAG, "There was an error getting Deep Link data: " + dlError.toString());
                 return;
             } else {
                 // dlStatus == DeepLinkResult.Status.FOUND) {
                 Log.d(LOG_TAG, "Deep link found");
             }
             DeepLink deepLinkObj = deepLinkResult.getDeepLink();
             try {
                 Log.d(LOG_TAG, "The DeepLink data is: " + deepLinkObj.toString());
                 var deepLinkDestination = deepLinkObj.getDeepLinkValue();
                 // Your code for handling the deeplink data
             } catch (Exception e) {
                 Log.d(LOG_TAG, "DeepLink data came back null");
                 return;
             }
         }
      }
 }`;

 const code9Kotlin = `class AppsflyerBasicApp: Application(){
    override fun onCreate() {
      super.onCreate()
      //Deep Linking Handling
          AppsFlyerLib.getInstance().subscribeForDeepLink(object : DeepLinkListener {
            DeepLinkResult.Status.NOT_FOUND -> {
                Log.d(LOG_TAG, "Deep link not found")
                return
            }
            DeepLinkResult.Status.ERROR -> {
                // dlStatus == 
                val dlError = deepLinkResult.error
                Log.d(LOG_TAG, "There was an error getting Deep Link data: $dlError")
                return
            }
            else -> {
                // DeepLinkResult.Status.FOUND  
                Log.d(LOG_TAG, "Deep link found")
            }
            val deepLinkObj: DeepLink = deepLinkResult.deepLink
            try {
                Log.d(LOG_TAG, "The DeepLink data is: $deepLinkObj")
                var deepLinkDestination = deepLinkObj.deepLinkValue
                 // Your code for handling the deeplink data
            } catch (e: Exception) {
                Log.d(LOG_TAG, "DeepLink data came back null")
                return
            } 
          }        
    }
  }`;

  const codeBlockProps9: ICodeBlock = {
    codePrimary: code9Java,
    codeSecondary: code9Kotlin,
    showLineNumbers: true,
    highlight: "24-25",
    highlightSecondary: "23-24",
    scrollPosition: "bottom",
  };

  const code10Java = `public class ExampleApp extends application {
    @Override
    public void onCreate() {
      super.onCreate();
      ...
      appsflyer.subscribeForDeepLink(new DeepLinkListener(){
         @Override
         public void onDeepLinking(@NonNull DeepLinkResult deepLinkResult) {
             DeepLinkResult.Status dlStatus = deepLinkResult.getStatus();
             if (dlStatus == DeepLinkResult.Status.NOT_FOUND) {
                 Log.d(LOG_TAG, "Deep link not found");
                 return;
             } else if (dlStatus ==  DeepLinkResult.Status.ERROR){
                 DeepLinkResult.Error dlError = deepLinkResult.getError();
                 Log.d(LOG_TAG, "There was an error getting Deep Link data: " + dlError.toString());
                 return;
             } else {
                 // dlStatus == DeepLinkResult.Status.FOUND) {
                 Log.d(LOG_TAG, "Deep link found");
             }
             DeepLink deepLinkObj = deepLinkResult.getDeepLink();
             try {
                 Log.d(LOG_TAG, "The DeepLink data is: " + deepLinkObj.toString());
                 var deepLinkDestination = deepLinkObj.getDeepLinkValue();
                 if (deepLinkObj.isDeferred()) {
			            // Your code for deferred deep link
			           } else {
			            // Your code for direct deep link
			           }
             } catch (Exception e) {
                 Log.d(LOG_TAG, "DeepLink data came back null");
                  return;
             }
         }
      }
 }`;

 const code10Kotlin = `class AppsflyerBasicApp: Application(){
    override fun onCreate() {
      super.onCreate()
      //Deep Linking Handling
          AppsFlyerLib.getInstance().subscribeForDeepLink(object : DeepLinkListener {
            DeepLinkResult.Status.NOT_FOUND -> {
                Log.d(LOG_TAG, "Deep link not found")
                return
            }
            DeepLinkResult.Status.ERROR -> {
                // dlStatus == 
                val dlError = deepLinkResult.error
                Log.d(LOG_TAG, "There was an error getting Deep Link data: $dlError")
                return
            }
            else -> {
                // DeepLinkResult.Status.FOUND  
                Log.d(LOG_TAG, "Deep link found")
            }
            val deepLinkObj: DeepLink = deepLinkResult.deepLink
            try {
                Log.d(LOG_TAG, "The DeepLink data is: $deepLinkObj")
                var deepLinkDestination = deepLinkObj.deepLinkValue
                if (deepLinkObj.isDeferred == true) {
                  Log.d(LOG_TAG, "This is a deferred deep link")
                  // Your code for deferred deep link
                } else {
                  Log.d(LOG_TAG, "This is a direct deep link")
                  // Your code for direct deep link
                }
                // Your deep link code here
            } catch (e: Exception) {
                Log.d(LOG_TAG, "DeepLink data came back null")
                return
            } 
          }        
    }
  }`;

  const codeBlockProps10: ICodeBlock = {
    codePrimary: code10Java,
    codeSecondary: code10Kotlin,
    showLineNumbers: true,
    highlight: "25-29",
    highlightSecondary: "24-30",
    scrollPosition: "bottom",
  };

  const steps = [
    {
      label: t("dl_page8_android_importCodeLibraries"),
      component: (
        <>
          <Typography>
            {t("dl_page8_android_importLibrariesInstructions")}
          </Typography>
          <DJCodeBlock {...codeBlockProps_implement} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_page10_android_step2Label"),
      component: (
        <>
          <DJCodeBlock {...codeBlockProps5} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_page11_android_step3Label"),
      component: (
        <>
          <Typography>
            {t("dl_page11_android_onDeepLinkingInstructions")}
            <code> onDeepLinking() </code>
            {t("dl_page11_android_onDeepLinkingInstructions_b")}
            <code> onDeepLinking() </code>
            {t("dl_page11_android_onDeepLinkingInstructions_c")}
            <code> DeepLinkResult </code>
            {t("dl_page11_android_onDeepLinkingInstructions_d")}
            <code> onDeepLinking()</code>.
          </Typography>
          <DJCodeBlock {...codeBlockProps6} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_page12_android_step4Label"),
      component: (
        <>
          <Typography>
            {t("dl_page12_android_retrieveUDLStatusInstructions")}
            <code> getStatus() </code>
            {t("dl_page12_android_retrieveUDLStatusInstructions_b")}
          </Typography>

          {/* Use DJCodeBlock for code formatting */}
          <DJCodeBlock {...codeBlockProps7} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_page13_android_step5Label"),
      component: (
        <>
          <Typography>
            {t("dl_page13_android_retrieveDeepLinkObjectInstructions")}
            <code> getDeepLink() </code>
            {t("dl_page13_android_retrieveDeepLinkObjectInstructions_b")}
          </Typography>

          {/* Use DJCodeBlock for code formatting */}
          <DJCodeBlock {...codeBlockProps8} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_page14_android_step6Label"),
      component: (
        <>
          <Typography>
            {t("dl_page14_android_useDeepLinkValueInstructions")}
            <code> deep_link_value </code>
            {t("dl_page14_android_useDeepLinkValueInstructions_b")}
            <code> deep_link_value </code>
            {t("dl_page14_android_useDeepLinkValueInstructions_c")}
            <code> getDeepLinkValue() </code>
            {t("dl_page14_android_useDeepLinkValueInstructions_d")}
          </Typography>

          {/* Use DJCodeBlock for code formatting */}
          <DJCodeBlock {...codeBlockProps9} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_page15_android_step7Label"),
      component: (
        <>
          <Typography>
            <code>isDeferred()</code>{" "}
            {t("dl_page15_android_optionalCheckInstructions")}
          </Typography>

          {/* Use DJCodeBlock for code formatting */}
          <DJCodeBlock {...codeBlockProps10} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  // add branded domain step only if URL does not include onelink.me
  if (extractedDomain.indexOf("onelink.me") == -1) {
    const code_brandedJava = `public class ExampleApp extends application {
    @Override
    public void onCreate() {
      super.onCreate();
      AppsFlyerLib.getInstance().setOneLinkCustomDomain("${extractedDomain}"); 
      ...
      appsflyer.subscribeForDeepLink(new DeepLinkListener(){
        // TODO - will be implemented in the following steps 
      }
      ...   
    }
 }`;

 const code_brandedKotlin = `class AppsflyerBasicApp: Application(){
  override fun onCreate() {
      super.onCreate()
      AppsFlyerLib.getInstance().setOneLinkCustomDomain("${extractedDomain}") 

      //Deep Linking Handling
          AppsFlyerLib.getInstance().subscribeForDeepLink(object : DeepLinkListener {
          // TODO - will be implemented in the following steps 
          }
  }
}`;

    const codeBlockProps_branded: ICodeBlock = {
      codePrimary: code_brandedJava,
      codeSecondary: code_brandedKotlin,
      showLineNumbers: true,
      highlight: "5",
      highlightSecondary: "4",
    };
    steps.splice(2, 0, {
      label: t("dl_brandedDomains_label"),
      component: (
        <>
          {/* Use DJCodeBlock for code formatting */}
          <DJCodeBlock {...codeBlockProps_branded} />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    });
  }

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} marginBottom={-10} style={{ minHeight: "50vh" }}>
        <Box marginBottom={2}>
          <Typography variant="h1">
            {t("dl_page9_android_pageTitle")}
          </Typography>
          <Stepper
            activeStep={activeStep}
            style={{ border: "none" }}
            orientation="vertical"
          >
            {steps.map(
              ({ label, component, shouldDisplayNavigationButtons }) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    {component}
                    {shouldDisplayNavigationButtons && (
                      <Box sx={{ mt: 2 }}>
                        {activeStep !== 0 && (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={handleBack}
                            // sx={{ mt: 2 }}
                          >
                            {t("general_Back")}
                          </Button>
                        )}
                        {activeStep !== steps.length - 1 && (
                          <Button
                            // sx={{ mt: 2 }}
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                          >
                            {activeStep === steps.length - 1
                              ? t("general_Finish")
                              : t("general_Next")}
                          </Button>
                        )}
                      </Box>
                    )}
                  </StepContent>
                </Step>
              )
            )}
          </Stepper>
        </Box>
      </Box>

      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(DL_implementAndroid);
