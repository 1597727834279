import { useState, useEffect } from "react";
import { Button, Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DJSimpleCodeBlock, ICodeBlock } from "../../main/components/codeblock";
import {
  IProgress,
  ISessionProps,
} from "../../main/components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@appsflyer/fe-ui-core";
import transition from "../../main/components/transition";

const ProGuardPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [progress, setProgress] = useState<IProgress[]>([]);

  useEffect(() => {
    const state: ISessionProps = location.state as ISessionProps;
    setProgress(state?.progress);
  }, [location.state]);

  const handleClick = () => {
    const progressArr: IProgress[] = progress.slice();
    progressArr.push({
      id: "startsdk_startSDK",
      text: "progress_startsdk_startSDK",
      order: 5,
    });
  
    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    };
  
    navigate(`/startsdk/${location.state.os.toLowerCase()}/startsdk`, {
      state: progressState, // Passes the updated state
    });
  };
  
  
  const code = `-keep class com.appsflyer.** { *; } 
  -keep class kotlin.jvm.internal.** { *; }`;

  const codeProps: ICodeBlock = {
    codePrimary: code,
    language: "swift",
    showLineNumbers: true,
  };

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box display={"flex"}>
          <Typography variant="h1" fontWeight={"bold"}>
            {t("startSDK_ProGuardPage_android_optional")}{" "}
            {t("startSDK_ProGuardPage_android_AddingProGuardRules")}
          </Typography>
        </Box>

        <Typography variant="h2" fontWeight={"bold"} mt={5}>
          {t("startSDK_ProGuardPage_android_AddProGuardRules")}
        </Typography>
        <Typography variant="body1" mb={2}>
        {t("startSDK_ProGuardPage_android_ProGuardWarning_first")}{" "}
          <code>AFKeystoreWrapper</code>{" "}
        {t("startSDK_ProGuardPage_android_ProGuardWarning_second")}{" "}
        {t("startSDK_ReferrerPage_android_OnlyAddDependency")}{" "}
          (<code>build.gradle (Module :app)</code>)
        </Typography>

        <br />
        <DJSimpleCodeBlock {...codeProps}></DJSimpleCodeBlock>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(ProGuardPage);
