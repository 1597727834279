import { useState, ChangeEvent, useEffect } from "react";
import {
  Button,
  Box,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DJSimpleCodeBlock, ICodeBlock } from "../../main/components/codeblock";
import { useLocation, useNavigate } from "react-router-dom";
import { IProgress, ISessionProps } from "../../main/components/IProgress";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { Typography } from "@appsflyer/fe-ui-core";
import transition from "../../main/components/transition";
import {
  amplitudeTrack_continue,
  amplitudeTrack_inputChange,
  InputType,
} from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const codeProps_coco: ICodeBlock = {
  codePrimary: `pod 'AppsFlyerFramework'`,
  language: "swift",
  showLineNumbers: true,
};
const codeProps_coco_strict: ICodeBlock = {
  codePrimary: `pod 'AppsFlyerFramework/Strict`,
  language: "swift",
  showLineNumbers: true,
};

const codeProps_carthage: ICodeBlock = {
  codePrimary: `binary "https://raw.githubusercontent.com/AppsFlyerSDK/AppsFlyerFramework/master/Carthage/appsflyer-strict.json" ~> 6.3.2`,
  language: "swift",
  showLineNumbers: true,
};

const codeProps_carthage_strict: ICodeBlock = {
  codePrimary: `binary "https://raw.githubusercontent.com/AppsFlyerSDK/AppsFlyerFramework/master/Carthage/appsflyer-ios.json"`,
  language: "swift",
  showLineNumbers: true,
};

const codeProps_spm_static: ICodeBlock = {
  codePrimary: `https://github.com/AppsFlyerSDK/AppsFlyerFramework-Static`,
  language: "swift",
  showLineNumbers: true,
};

const codeProps_spm_dynamic: ICodeBlock = {
  codePrimary: `https://github.com/AppsFlyerSDK/AppsFlyerFramework-Dynamic`,
  language: "swift",
  showLineNumbers: true,
};

const codeProps_spm_strict: ICodeBlock = {
  codePrimary: `https://github.com/AppsFlyerSDK/AppsFlyerFramework-Strict`,
  language: "swift",
  showLineNumbers: true,
};

const InstallSDK = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [progress, setProgress] = useState<IProgress[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();
  const [value, setValue] = useState(t("StartSDK_installSDK_ios_regularmode"));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (location.state) {
      const state: ISessionProps = location.state as ISessionProps;
      setProgress(state?.progress);
    }
  }, [location.state]);

  const handleClickNext = () => {
    const progressArr: IProgress[] = progress.slice();
    progressArr.push({
      id: "startsdk_startSDK",
      text: "progress_startsdk_startSDK",
      order: 5,
    });
    // Initialize progress with a copy of progressArr
    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    };
    amplitudeTrack_continue(location.state);

    navigate(`/startsdk/${location.state?.os.toLowerCase()}/startsdk`, {
      state: progressState,
    });
  };

  const handleChangeMode = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    amplitudeTrack_inputChange(
      location.state,
      InputType.Radio,
      "strictOrRegularMode",
      event.target.value
    );
  };

  const [tabValue, setTabValue] = useState(0);
  function tabIndexProps(idx) {
    return {
      index: idx,
      id: `tab-${idx}`,
      "aria-controls": `tab-panel-${idx}`,
    };
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    setActiveStep(0);
    if (newValue === 2) {
      setValue("Statically Linked Library");
    } else {
      setValue(t("StartSDK_installSDK_ios_regularmode"));
    }
  };

  const steps_carthage = [
    // carthage
    {
      label: t("StartSDK_installSDK_ios_step1").replace(
        "CocoaPods",
        "Carthage"
      ),
      component: (
        <>
          <Typography>
            {t("StartSDK_installSDK_ios_download").replace(
              "CocoaPods",
              "Carthage"
            )}
          </Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("StartSDK_installSDK_ios_step2"),
      component: (
        <>
          <Typography>
            {t("StartSDK_installSDK_ios_step2_carthage")}
            <code>Cartfile</code> binary:
          </Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={value}
            onChange={handleChangeMode}
            name="radio-buttons-group"
            sx={{ mt: 2 , mb:1}}
          >
            <FormControlLabel
              value={t("StartSDK_installSDK_ios_regularmode")}
              control={<Radio />}
              label={t("StartSDK_installSDK_ios_regularmode")}
            />
            <FormControlLabel
              value={t("StartSDK_installSDK_ios_strictmode")}
              control={<Radio />}
              label={t("StartSDK_installSDK_ios_strictmode")}
            />
          </RadioGroup>
          {value === t("StartSDK_installSDK_ios_regularmode") ? (
            <>
              <DJSimpleCodeBlock {...codeProps_carthage}></DJSimpleCodeBlock>
            </>
          ) : (
            <>
              <DJSimpleCodeBlock {...codeProps_carthage_strict}></DJSimpleCodeBlock>
            </>
          )}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  const steps_coco = [
    {
      label: t("StartSDK_installSDK_ios_step1"),
      component: (
        <>
          <Typography>{t("StartSDK_installSDK_ios_download")}</Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("StartSDK_installSDK_ios_step2"),
      component: (
        <>
          <Typography>{t("StartSDK_installSDK_ios_Add")}</Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={value}
            onChange={handleChangeMode}
            name="radio-buttons-group"
            sx={{ mt: 2 , mb: 1}}
          >
            <FormControlLabel
              value={t("StartSDK_installSDK_ios_regularmode")}
              control={<Radio />}
              label={t("StartSDK_installSDK_ios_regularmode")}
            />
            <FormControlLabel
              value={t("StartSDK_installSDK_ios_strictmode")}
              control={<Radio />}
              label={t("StartSDK_installSDK_ios_strictmode")}
            />
          </RadioGroup>
          {value === t("StartSDK_installSDK_ios_regularmode") ? (
            <>
              <DJSimpleCodeBlock {...codeProps_coco}></DJSimpleCodeBlock>
            </>
          ) : (
            <>
              <DJSimpleCodeBlock {...codeProps_coco_strict}></DJSimpleCodeBlock>
            </>
          )}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("StartSDK_installSDK2_ios_step3"),
      component: (
        <>
          <Typography>
            {t("StartSDK_installSDK2_ios_add")}
            <AmplitudeLink
              target="_blank"
              href={"https://cocoapods.org/pods/AppsFlyerFramework"}
            >
              {t("StartSDK_installSDK2_ios_addLatest")}
            </AmplitudeLink>{" "}
            {t("StartSDK_installSDK2_ios_add2")}
          </Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("StartSDK_installSDK2_ios_step4"),
      component: (
        <>
          <Typography>
            {t("StartSDK_installSDK2_ios_xcode")}
            <code> .xcworkspace </code>
            {t("StartSDK_installSDK2_ios_xcode_b")}
            <code> .xcworkspace </code>
            {t("StartSDK_installSDK2_ios_xcode_c")}
            <code> .xcodeproj </code>
            {t("StartSDK_installSDK2_ios_xcode_d")}
          </Typography>
          <Typography mt={2}>{t("StartSDK_installSDK2_ios_ifyou")}</Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  const steps_spm = [
    // carthage
    {
      label: t("StartSDK_installSDK_ios_step1_spm"),
      component: (
        <>
          <Typography>
            {t("StartSDK_installSDK_ios_step1_spm_text")}
            <code>{`File > Add Packages:`}</code>
          </Typography>
          <img
            src={
              "https://files.readme.io/ba6a743-Screenshot_2022-12-20_at_14.44.18.png"
            }
            width={700}
          ></img>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("StartSDK_installSDK_ios_step2_spm"),
      component: (
        <>
          <Typography>{t("StartSDK_installSDK_ios_step2_spm_text")}</Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={value}
            onChange={handleChangeMode}
            name="radio-buttons-group"
            sx={{ mt: 2 }}
          >
            <FormControlLabel
              value={"Statically Linked Library"}
              control={<Radio />}
              label={"Statically Linked Library"}
            />
            <FormControlLabel
              value={"Dynamically Linked Library"}
              control={<Radio />}
              label={"Dynamically Linked Library"}
            />
            <FormControlLabel
              value={"Strict (No IDFA Collection) Library"}
              control={<Radio />}
              label={"Strict (No IDFA Collection) Library"}
            />
          </RadioGroup>
          {value === "Statically Linked Library" && (
            <Box my={3}>
              <DJSimpleCodeBlock {...codeProps_spm_static}></DJSimpleCodeBlock>
            </Box>
          )}
          {value === "Dynamically Linked Library" && (
            <Box my={3}>
              <DJSimpleCodeBlock {...codeProps_spm_dynamic}></DJSimpleCodeBlock>
            </Box>
          )}
          {value === "Strict (No IDFA Collection) Library" && (
            <Box my={3}>
              <DJSimpleCodeBlock {...codeProps_spm_strict}></DJSimpleCodeBlock>
            </Box>
          )}
          <img
            src={
              "https://files.readme.io/183fc98-Screenshot_2022-12-20_at_14.42.23.png"
            }
            width={700}
          ></img>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("StartSDK_installSDK_ios_step3_spm"),
      component: (
        <>

          <img
            src={
              "https://files.readme.io/5c26d51-Screenshot_2022-12-20_at_14.42.53.png"
            }
            width={700}
          ></img>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("StartSDK_installSDK_ios_step4_spm"),
      component: (
        <>
          <img
            src={
              "https://files.readme.io/f85b0d9-Screenshot_2022-12-20_at_14.43.15.png"
            }
            width={700}
          ></img>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Typography variant="h1">
          {t("StartSDK_stepsPage_ios_installSDK")}
        </Typography>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="tabs general by index"
        >
          <Tab label="CocoaPods" {...tabIndexProps(0)} />
          <Tab label="Carthage" {...tabIndexProps(1)} />
          <Tab label="Swift Package Manager" {...tabIndexProps(2)} />
        </Tabs>
        <Stepper
          activeStep={activeStep}
          style={{ border: "none" }}
          orientation="vertical"
        >
          {(tabValue === 0
            ? steps_coco
            : tabValue === 1
            ? steps_carthage
            : steps_spm
          ).map(({ label, component, shouldDisplayNavigationButtons }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {component}
                {shouldDisplayNavigationButtons && (
                  <Box sx={{ mt: 2 }}>
                    {activeStep !== 0 && (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleBack}
                        // sx={{ mt: 2, mr: 1 }}
                      >
                        {t("general_Back")}
                      </Button>
                    )}
                    {activeStep !==
                      (tabValue === 0
                        ? steps_coco
                        : tabValue === 1
                        ? steps_carthage
                        : steps_spm
                      ).length -
                        1 && (
                      <Button
                        // sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep ===
                        (tabValue === 0
                          ? steps_coco
                          : tabValue === 1
                          ? steps_carthage
                          : steps_spm
                        ).length -
                          1
                          ? t("general_Finish")
                          : t("general_Next")}
                      </Button>
                    )}
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClickNext()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(InstallSDK);
