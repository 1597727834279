/* eslint-disable */

import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
  DragEvent,
} from "react";
import { androidEvents, androidParams } from "./android/arrays";
import {
  Box,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Container,
  Chip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DJCodeBlock, ICodeBlock } from "../main/components/codeblock";
import { IProgress, ISessionProps, OSType } from "../main/components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import SendInappEventParamForm, { IOption } from "./SendInappEventParam";
import {
  Typography,
  TextField,
  Select,
  ChipSelection,
  MultiSelectMenu,
} from "@appsflyer/fe-ui-core";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import {
  amplitudeTrack_continue,
  amplitudeTrack_inputChange,
  InputType,
} from "../services/utils";
import AmplitudeLink from "../services/AmplitudeLink";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CloseIcon from "@mui/icons-material/Close";
import { EvgenEvent, EvgenEventsObject } from "./IEvgen";
import { iosEvents, iosParameter } from "./ios/arrays";
import ExplanationTooltip from "../main/components/ExplanationTooltip";
import transition from "../main/components/transition";
import DragAndDropUpload from "../main/components/DragDropUpload";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EventsTable = (props: EvgenEventsObject) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("ip_AppEvents_Evgen_ename")}</TableCell>
            <TableCell>{t("ip_AppEvents_Evgen_epurpose")}</TableCell>
            <TableCell>{t("ip_AppEvents_Evgen_etrigger")}</TableCell>
            <TableCell>{t("ip_AppEvents_Evgen_eparams")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.events.map((row, indx) => (
            <TableRow key={row.eventIdentifier + "_" + indx} hover>
              <TableCell>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  gap={1}
                >
                  <div>
                    <Chip
                      label={row.eventType ?? ""}
                      size="medium"
                      variant="outlined"
                      color={
                        row.eventType == "predefined" ? "primary" : "success"
                      }
                    />
                  </div>
                  <code>{row.eventIdentifier}</code>
                </Box>
              </TableCell>
              <TableCell>{row.eventPurposeDescription}</TableCell>
              <TableCell>{row.eventTriggerDescription}</TableCell>
              <TableCell>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  {row.parameters.map((param, idx) => {
                    return (
                      <span key={param.parameterIdentifier + "_" + idx}>
                        <code>{param.parameterIdentifier}</code>
                      </span>
                    );
                  })}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface MultiSelectControlProps {
  selectedValue?: IOption;
  options: IOption[];
  onSelectedEventsChange?: (selectedValues: IOption[]) => void;
}

const MultiSelectControl = (props: MultiSelectControlProps) => {
  const [selectedEventsValues, setSelectedEventsValues] = useState<IOption[]>(
    []
  );
  const { t } = useTranslation();

  useEffect(() => {
    props.onSelectedEventsChange?.(selectedEventsValues);
  }, [selectedEventsValues]);

  const shouldRenderDeleteIcon = selectedEventsValues.length > 0;
  return (
    <ChipSelection
      label={t("ipAppEvents_Evgen_filter") + ": "}
      chipDefaultValue="All"
      controlType="multiSelect"
      value={selectedEventsValues}
      onChange={setSelectedEventsValues}
      deleteIcon={
        shouldRenderDeleteIcon ? <CloseIcon fontSize="small" /> : undefined
      }
      onDelete={
        shouldRenderDeleteIcon ? () => setSelectedEventsValues([]) : undefined
      }
      renderComponent={({ value, onChange, isOpen, setIsOpen, anchorRef }) => (
        <MultiSelectMenu
          options={props.options}
          values={value}
          {...{
            onChange,
            isOpen,
            setIsOpen,
            anchorRef,
          }}
        />
      )}
    />
  );
};

const SendInapp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [progress, setProgress] = useState<IProgress[]>([]);
  const { t } = useTranslation();

  // Radio group for the first section
  const [selectedRadioOne, setSelectedRadioOne] = useState("Predefined");
  const [isCustomOne, setIsCustomOne] = useState(false);
  const [chosenEventName, setChosenEventName] = useState(null);
  const [customEventName, setCustomEventName] = useState<string>("");
  const [eventParam1, setEventParam1] = useState<undefined | string>("");
  const [eventParam2, setEventParam2] = useState<undefined | string>("");
  const [eventParam3, setEventParam3] = useState<undefined | string>("");
  const [eventParam4, setEventParam4] = useState<undefined | string>("");
  const [eventParam5, setEventParam5] = useState<undefined | string>("");
  const [paramAdded, setParamAdded] = useState(false);
  const [paramCount, setParamCount] = useState(1);
  const [responseListenerRequired, setResponseListenerRequired] =
    useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAddParameter = () => {
    if (paramCount < 5) {
      setParamCount((prevCount) => prevCount + 1);
      setParamAdded(true); // Set the flag when a parameter is added
    }
    switch (paramCount + 1) {
      case 2:
        setIsShowEventParam2(true);
        break;
      case 3:
        setIsShowEventParam3(true);
        break;
      case 4:
        setIsShowEventParam4(true);
        break;
      case 5:
        setIsShowEventParam5(true);
        break;
      default:
        break;
    }
  };

  const handleRemoveParameter = () => {
    if (paramCount > 1) {
      setParamCount((prevCount) => prevCount - 1);
      setParamAdded(true); // Set the flag when a parameter is added
    }
    switch (paramCount + 1) {
      case 2:
        setIsShowEventParam2(false);
        break;
      case 3:
        setIsShowEventParam3(false);
        break;
      case 4:
        setIsShowEventParam4(false);
        break;
      case 5:
        setIsShowEventParam5(false);
        break;
      default:
        break;
    }
  };

  const [isShowEventParam2, setIsShowEventParam2] = useState(false);
  const [isShowEventParam3, setIsShowEventParam3] = useState(false);
  const [isShowEventParam4, setIsShowEventParam4] = useState(false);
  const [isShowEventParam5, setIsShowEventParam5] = useState(false);

  const [generatedEventCodePrimary, setGeneratedEventCodePrimary] =
    useState<string>(`Map<String, Object> eventValues = new HashMap<String, Object>();
eventValues.put(AFInAppEventParameterName.<< Event Param  >>, <<PLACE_HOLDRER_FOR_PARAM_VALUE>>);
AppsFlyerLib.getInstance().logEvent(getApplicationContext(),
    << Event Name  >>, eventValues);`);

  const [generatedEventCodeSecondary, setGeneratedEventCodeSecondary] =
    useState<string>(`val eventValues = HashMap<String, Any>()
eventValues[<< Event Param  >>] = <<PLACE_HOLDRER_FOR_PARAM_VALUE>>

AppsFlyerLib.getInstance().logEvent(
    applicationContext,
    << Event Name  >> , eventValues)`);

  const isPrefedinedParam = (param: string) => {
    if (location.state.os === OSType.Android) {
      return androidParams.find((prm) => prm.android_constant_name === param);
    } else {
      return iosParameter.find((prm) => prm.ios_constant_name === param);
    }
  };

  useEffect(() => {
    // Step 3: Sending the event
    if (location.state.os === OSType.Android) {
      // Update generatedEventCode based on selectedRadioTwo
      const baseCodeJava = `AppsFlyerLib.getInstance().logEvent(getApplicationContext(),
    ${
      (chosenEventName && !isCustomOne) || (customEventName && isCustomOne)
        ? isCustomOne
          ? `"${customEventName}", eventValues);` ??
            `<< Event Param  >>, eventValues);`
          : chosenEventName.value + `, eventValues);` ??
            `<< Event Param  >>, eventValues);`
        : `<< Event Name  >>, eventValues);`
    }`;

      const baseCodeKotlin = `
AppsFlyerLib.getInstance().logEvent(
    applicationContext,
    ${
      (chosenEventName && !isCustomOne) || (customEventName && isCustomOne)
        ? isCustomOne
          ? `"${customEventName}" , eventValues)` ??
            `<< Event Param  >>, eventValues)`
          : chosenEventName.value + ` , eventValues)` ??
            `<< Event Param  >> , eventValues)`
        : `<< Event Name  >> , eventValues)`
    }`;

      const paramLinesJava = Array(paramCount)
        .fill(0)
        .map((_, index) => {
          const eventParam = eval(`eventParam${index + 1}`);
          return `${
            eventParam !== ""
              ? `eventValues.put(${
                  isPrefedinedParam(eventParam)
                    ? `AFInAppEventParameterName.${eventParam}`
                    : `"${eventParam}"`
                },`
              : "eventValues.put(<< Event Param  >>,"
          } <<PLACE_HOLDRER_FOR_PARAM_VALUE>>);`;
        })
        .filter(Boolean);

      const paramLinesKotlin = Array(paramCount)
        .fill(0)
        .map((_, index) => {
          const eventParam = eval(`eventParam${index + 1}`);
          return `${
            eventParam !== ""
              ? `eventValues[${
                  isPrefedinedParam(eventParam)
                    ? `AFInAppEventParameterName.${eventParam}] = `
                    : `"${eventParam}" ] =`
                }`
              : "eventValues[<< Event Param  >>] ="
          } <<PLACE_HOLDRER_FOR_PARAM_VALUE>>`;
        })
        .filter(Boolean);

      const updatedGeneratedEventCodeJava = `Map<String, Object> eventValues = new HashMap<String, Object>();
${paramLinesJava.join("\n")}
${baseCodeJava}`;

      const updatedGeneratedEventCodeKotlin = `val eventValues = HashMap<String, Any>()
${paramLinesKotlin.join("\n")}
${baseCodeKotlin}`;

      setGeneratedEventCodePrimary(updatedGeneratedEventCodeJava);
      setGeneratedEventCodeSecondary(updatedGeneratedEventCodeKotlin);
    } else {
      // Update generatedEventCode based on selectedRadioTwo
      // Swift Step 2
      const baseCodeSwift = `AppsFlyerLib.shared().logEvent(name: ${
        (chosenEventName && !isCustomOne) || (customEventName && isCustomOne)
          ? isCustomOne
            ? `"${customEventName ?? "<<Event name>>"}"`
            : chosenEventName.value ?? "<<Event name>>"
          : "<<Event name>>"
      },`;

      // Objective-C Step 2
      const baseCodeObjC = `[[AppsFlyerLib shared] logEvent:${
        (chosenEventName && !isCustomOne) || (customEventName && isCustomOne)
          ? isCustomOne
            ? `"${customEventName ?? "<<Event name>>"}"`
            : chosenEventName.value ?? "<<Event name>>"
          : "<<Event name>>"
      }`;

      const paramLinesSwift = Array(paramCount)
        .fill(0)
        .map((_, index) => {
          const eventParam = eval(`eventParam${index + 1}`);
          return index === 0
            ? `  ${
                eventParam !== ""
                  ? `${
                      isPrefedinedParam(eventParam)
                        ? eventParam
                        : `"${eventParam}"`
                    }:`
                  : "<<Event Param>>:"
              } << Param value >>`
            : isShowEventParam2 && eventParam !== ""
            ? `      ${
                isPrefedinedParam(eventParam) ? eventParam : `"${eventParam}"`
              }: << Param value >>`
            : "";
        })
        .filter(Boolean)
        .map((line, index, arr) =>
          index < arr.length - 1 ? `${line},` : line
        );

      const paramLinesObjC = Array(paramCount)
        .fill(0)
        .map((_, index) => {
          const eventParam = eval(`eventParam${index + 1}`);
          return index === 0
            ? `  ${
                eventParam !== ""
                  ? `${
                      isPrefedinedParam(eventParam)
                        ? eventParam
                        : `"${eventParam}"`
                    }:`
                  : "<<Event Param>>: "
              } @<< Param value >>`
            : isShowEventParam2 && eventParam !== ""
            ? `                                               ${
                isPrefedinedParam(eventParam) ? eventParam : `@"${eventParam}"`
              }: << Param value >>`
            : "";
        })
        .filter(Boolean)
        .map((line, index, arr) => (index < arr.length - 1 ? `${line}` : line));

      // Swift
      const updatedGeneratedEventCodeSwift = `${baseCodeSwift}
    values: [
    ${paramLinesSwift.join("\n")}
    ]
  );`;
      // Objective-C
      const updatedGeneratedEventCodeObjC = `${baseCodeObjC}
                                         withValues:@{
                                             ${paramLinesObjC.join("\n")}
                                         }];`;
      setGeneratedEventCodePrimary(updatedGeneratedEventCodeSwift);
      setGeneratedEventCodeSecondary(updatedGeneratedEventCodeObjC);
    }
    setParamAdded(false);
  }, [
    chosenEventName,
    customEventName,
    isCustomOne,
    ...Array(5)
      .fill(0)
      .map((_, index) => eval(`eventParam${index + 1}`)),
    isShowEventParam2,
    paramAdded,
  ]);

  const handleRadioChangeOne = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    amplitudeTrack_inputChange(
      location.state,
      InputType.Radio,
      "isCustomEventParamName",
      event
    );
    setIsCustomOne(value === "custom");
    setSelectedRadioOne(value);
  };

  useEffect(() => {
    const state: ISessionProps = location.state as ISessionProps;
    setProgress(state?.progress);
  }, [location.state]);

  const { os } = location.state || { os: "" };

  const handleClick = () => {
    const progressArr: IProgress[] = progress.slice();
    progressArr[progressArr.length - 1].isListener = responseListenerRequired;

    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    };

    if (tabValue == 1) {
      progressState.isEvgenTesting = false;
      progressState["eventName"] = isCustomOne
        ? customEventName
        : chosenEventName.label;
      progressState["eventParams"] = [
        eventParam1,
        eventParam2,
        eventParam3,
        eventParam4,
        eventParam5,
      ]
        .filter((x) => x)
        .map((param) => {
          // look for the parameter within the param list and return the event parameter name
          // (unless it's custom and then the original value is returned)
          if (location.state.os === OSType.Android) {
            return (
              androidParams.find((x) => x.android_constant_name === param)
                ?.event_parameter_name ?? param
            );
          } else {
            return (
              iosParameter.find((x) => x.ios_constant_name === param)
                ?.event_parameter_name ?? param
            );
          }
        });
      amplitudeTrack_continue(location.state, {
        responseListenerRequired,
        eventName: isCustomOne ? customEventName : chosenEventName.label,
        eventParams: [
          eventParam1,
          eventParam2,
          eventParam3,
          eventParam4,
          eventParam5,
        ],
      });
    } else if (tabValue == 0) {
      progressState.isEvgenTesting = true;
      progressState["evgenEventsObject"] = evgenEventsObject;

      amplitudeTrack_continue(location.state, {
        responseListenerRequired,
        isEvgenTesting: true,
        evgenEventsObject,
      });
    }

    navigate(`/inapp/${os.toLowerCase()}/testtype`, {
      state: progressState,
    });
  };

  const codeProps: ICodeBlock = {
    codePrimary: `import com.appsflyer.AppsFlyerLib;`,
    codeSecondary: `import com.appsflyer.AppsFlyerLib`,
    showLineNumbers: true,
  };
  const codeProps2: ICodeBlock = {
    codePrimary: `import com.appsflyer.AppsFlyerLib;
import com.appsflyer.AFInAppEventType; // Predefined event names
import com.appsflyer.AFInAppEventParameterName; // Predefined parameter names`,
    codeSecondary: `import com.appsflyer.AppsFlyerLib
import com.appsflyer.AFInAppEventType // Predefined event names
import com.appsflyer.AFInAppEventParameterName // Predefined parameter names`,
    highlight: "2-3",
    highlightSecondary: "2-3",
    showLineNumbers: true,
  };

  const options: IOption[] =
    location.state.os === OSType.Android
      ? androidEvents.map((event) => ({
          value: event["android_constant_name"],
          label: event["event_name"],
        }))
      : iosEvents.map((event) => ({
          value: event["ios_constant_name"],
          label: event["event_name"],
        }));
  const [inappCodePrimary, setInappCodePrimary] = useState<string>(
    location.state.os === OSType.Android
      ? `Map<String, Object> eventValues = new HashMap<String, Object>();
  eventValues.put(AFInAppEventParameterName.<< Event Param  >>, <<PLACE_HOLDRER_FOR_PARAM_VALUE>>);
  AppsFlyerLib.getInstance().logEvent(getApplicationContext(),
      << Event Name  >>, eventValues);`
      : ``
  );
  const [inappCodeSecondary, setInappCodeSecondary] = useState<string>(
    location.state.os === OSType.Android
      ? `val eventValues = HashMap<String, Any>()
eventValues[<< Event Param  >>] = <<PLACE_HOLDRER_FOR_PARAM_VALUE>>

AppsFlyerLib.getInstance().logEvent(
    applicationContext,
    << Event Name  >> , eventValues)`
      : ``
  );
  const [inappCodeWListenerPrimary, setInappCodeWListenerPrimary] =
    useState<string>("");
  const [inappCodeWListenerSecondary, setInappCodeWListenerSecondary] =
    useState<string>("");

  const [evgenInappCodeJava, setEvgenInappCodePrimary] = useState<string>("");
  const [evgenInappCodeKotlin, setEvgenInappCodeSecondary] = useState<string>("");

  const [responseListRequired, setResponseListRequired] =
    useState<string>("No");

  const handleResponseChange = (event) => {
    setResponseListRequired(event.target.value);
    amplitudeTrack_inputChange(
      location.state,
      InputType.Radio,
      "responseListRequired",
      event.target.value
    );

    if (location.state.os === OSType.Android) {
      updateEventCodeAndroid();
      updateEventCodeEvgenAndroid(event.target.value);
    } else {
      updateCodeIOS();
      updateEventCodeEvgenIOS(event.target.value);
    }
  };

  const updateEventCodeAndroid = () => {
    const code1Java = `import com.appsflyer.AppsFlyerLib;
import com.appsflyer.AFInAppEventType; // Predefined event names
import com.appsflyer.AFInAppEventParameterName; // Predefined parameter names
import com.appsflyer.attribution.AppsFlyerRequestListener;

${inappCodePrimary.replace(
  `eventValues);`,
  `eventValues,
      new AppsFlyerRequestListener() {`
)}
        @Override
        public void onSuccess() {
          // YOUR CODE UPON SUCCESS
        }
        @Override
        public void onError(int i, String s) {
          // YOUR CODE FOR ERROR HANDLING
        }
      }
  );`;
    const code2Java = `import com.appsflyer.AppsFlyerLib;
import com.appsflyer.AFInAppEventType; // Predefined event names
import com.appsflyer.AFInAppEventParameterName; // Predefined parameter names

${inappCodePrimary}`;

    const code1Kotlin = `import com.appsflyer.AppsFlyerLib
import com.appsflyer.AFInAppEventType // Predefined event names
import com.appsflyer.AFInAppEventParameterName // Predefined parameter names
import com.appsflyer.attribution.AppsFlyerRequestListener

${inappCodeSecondary.replace(
  `eventValues)`,
  `eventValues,
    object : AppsFlyerRequestListener {`
)}
        override fun onSuccess() {
            Log.d(LOG_TAG, "Event sent successfully")
        }
        override fun onError(errorCode: Int, errorDesc: String) {
            Log.d(LOG_TAG, "Event failed to be sent:\\n" +
                    "Error code: " + errorCode + "\\n"
                    + "Error description: " + errorDesc)
        }
    })`;
    const code2Kotlin = `import com.appsflyer.AppsFlyerLib
import com.appsflyer.AFInAppEventType // Predefined event names
import com.appsflyer.AFInAppEventParameterName // Predefined parameter names

${inappCodeSecondary}`;

    if (responseListRequired == "No") {
      setInappCodeWListenerPrimary(code1Java);
      setInappCodeWListenerSecondary(code1Kotlin);
    } else {
      setInappCodeWListenerPrimary(code2Java);
      setInappCodeWListenerSecondary(code2Kotlin);
    }
  };

  const updateCodeIOS = () => {
    if (generatedEventCodePrimary === "") {
      setInappCodeWListenerPrimary(`AppsFlyerLib.shared().logEvent(name: <<Event name>>,
  values: [
    <<Event Param>>: << Param value >>
  ]
});`);
      setInappCodeWListenerSecondary(`[[AppsFlyerLib shared] logEvent:`);
      return;
    }

    const code1Swift = `${inappCodePrimary.replace(
      `]
  );`,
      ""
    )}],
    completionHandler: { (response: [String : Any]?, error: Error?) in
      if let response = response {
        print("In app event callback Success: ", response)
      }
      if let error = error {
        print("In app event callback ERROR:", error)
      }
    });`;
    const code2Swift = inappCodePrimary;

    const code1ObjC = `${inappCodeSecondary.replace(/withValues:@{|}];/g, (match) => {
      if (match === "withValues:@{") {
        return "eventValues:@{";
      }
      if (match === "}];") {
        return "";
      }
      return match; // Fallback for any unforeseen cases
    })}} completionHandler:^(NSDictionary *response, NSError *error) {
                                            if (response) {
                                                NSLog(@"In app event callback Success: %@", response);
                                            }
                                            if (error) {
                                                NSLog(@"In app event callback ERROR: %@", error);
                                            }
                                      }];`;
    const code2ObjC = inappCodeSecondary;

    if (responseListRequired == "No") {
      setInappCodeWListenerPrimary(code1Swift);
      setInappCodeWListenerSecondary(code1ObjC);
    } else {
      setInappCodeWListenerPrimary(code2Swift);
      setInappCodeWListenerSecondary(code2ObjC);
    }
  };

  const [filteredEventOptions, setFilteredEventOptions] = useState<IOption[]>(
    []
  );

  const filterEvgenEvents = (filteredOptions: IOption[]) => {
    setFilteredEventOptions(filteredOptions);
    if (location.state.os === OSType.Android) {
      updateEventCodeEvgenAndroid(
        responseListRequired,
        evgenEventsObject.events,
        filteredOptions
      );
    } else {
      updateEventCodeEvgenIOS(
        responseListRequired,
        evgenEventsObject.events,
        filteredOptions
      );
    }
  };

  const updateEventCodeEvgenAndroid = (
    isResponse: string,
    evgenEvents?: EvgenEvent[],
    filteredOptions?: IOption[]
  ) => {
    if (tabValue === 1) {
      return;
    }

    if (!evgenEvents || !evgenEvents.length) {
      evgenEvents = evgenEventsObject.events;
    }

    // apply filter
    if (filteredOptions) {
      if (filteredOptions.length) {
        const filters = filteredOptions.map((item) => item.value);
        evgenEvents = evgenEvents.filter((event) =>
          filters.includes(event.eventIdentifier)
        );
      }
    } else if (filteredEventOptions.length) {
      const filters = filteredEventOptions.map((item) => item.value);
      evgenEvents = evgenEvents.filter((event) =>
        filters.includes(event.eventIdentifier)
      );
    }

    const evgenInappCodeJava = evgenEvents.map((event) => {
      return `// ${event.eventIdentifier} definition:
Map<String, Object> eventValues = new HashMap<String, Object>();
${event.parameters.map((param) => {
  return `eventValues.put(${
    param.parameterType === "predefined"
      ? `AFInAppEventParameterName.${param.parameterIdentifier.toUpperCase()}`
      : `"${param.parameterIdentifier}"`
  }, <<PLACE_HOLDRER_FOR_PARAM_VALUE>>);`;
}).join(`
`)}
AppsFlyerLib.getInstance().logEvent(getApplicationContext(),
  ${
    event.eventType === "predefined"
      ? `AFInAppEventType.${event.eventIdentifier.toUpperCase()}`
      : `"${event.eventIdentifier}"`
  }, eventValues);`;
    }).join(`

`);

    const evgenInappCodeKotlin = evgenEvents.map((event) => {
      return `// ${event.eventIdentifier} definition:
val eventValues = HashMap<String, Any>()
${event.parameters.map((param) => {
  return `eventValues[${
    param.parameterType === "predefined"
      ? `AFInAppEventParameterName.${param.parameterIdentifier.toUpperCase()}`
      : `"${param.parameterIdentifier}"`
  }] = <<PLACE_HOLDRER_FOR_PARAM_VALUE>>`;
}).join(`
`)}
 AppsFlyerLib.getInstance().logEvent(
    applicationContext,
${
  event.eventType === "predefined"
    ? `AFInAppEventType.${event.eventIdentifier.toUpperCase()}`
    : `"${event.eventIdentifier}"`
}, eventValues)`;
    }).join(`

`);

    if (isResponse == "No") {
      setEvgenInappCodePrimary(evgenInappCodeJava);
      setEvgenInappCodeSecondary(evgenInappCodeKotlin);
    } else {
      const evgenInappCodeWListenerJava = evgenInappCodeJava
        .split(`eventValues);`)
        .join(
          `eventValues,
      new AppsFlyerRequestListener() {
        @Override
        public void onSuccess() {
          // YOUR CODE UPON SUCCESS
        }
        @Override
        public void onError(int i, String s) {
          // YOUR CODE FOR ERROR HANDLING
        }
      }
  );`
        );
      setEvgenInappCodePrimary(evgenInappCodeWListenerJava);

      const evgenInappCodeWListenerKotlin = evgenInappCodeJava
        .split(`eventValues)`)
        .join(
          `eventValues,
    object : AppsFlyerRequestListener {
        override fun onSuccess() {
            Log.d(LOG_TAG, "Event sent successfully")
        }
        override fun onError(errorCode: Int, errorDesc: String) {
            Log.d(LOG_TAG, "Event failed to be sent:\n" +
                    "Error code: " + errorCode + "\n"
                    + "Error description: " + errorDesc)
        }
    })`
        );
      setEvgenInappCodeSecondary(evgenInappCodeWListenerKotlin);
    }
  };

  const updateEventCodeEvgenIOS = (
    isResponse: string,
    evgenEvents?: EvgenEvent[],
    filteredOptions?: IOption[]
  ) => {
    if (tabValue === 1) {
      return;
    }

    if (!evgenEvents || !evgenEvents.length) {
      evgenEvents = evgenEventsObject.events;
    }

    // apply filter
    if (filteredOptions) {
      if (filteredOptions.length) {
        const filters = filteredOptions.map((item) => item.value);
        evgenEvents = evgenEvents.filter((event) =>
          filters.includes(event.eventIdentifier)
        );
      }
    } else if (filteredEventOptions.length) {
      const filters = filteredEventOptions.map((item) => item.value);
      evgenEvents = evgenEvents.filter((event) =>
        filters.includes(event.eventIdentifier)
      );
    }

    const evgenInappCodeSwift = evgenEvents.map((event) => {
      return `// ${event.eventIdentifier} definition:
AppsFlyerLib.shared().logEvent(name: ${
        iosEvents.find((evt) => evt.event_name == event.eventIdentifier)
          ?.ios_constant_name ?? `"${event.eventIdentifier}"`
      },
    ${
      event.parameters.length
        ? `values: [
      ${event.parameters.map((param) => {
        return `${
          iosParameter.find(
            (prm) => prm.event_parameter_name == param.parameterIdentifier
          )?.ios_constant_name ?? `"${param.parameterIdentifier}"`
        }: <<PLACE_HOLDRER_FOR_PARAM_VALUE>>`;
      }).join(`,
      `)}
    ]`
        : `[
    ]`
    }
  );`;
    }).join(`

`);

const evgenInappCodeObjC = evgenEvents.map((event) => {
  return `// ${event.eventIdentifier} definition:
[[AppsFlyerLib shared] logEventWithEventName:${
    iosEvents.find((evt) => evt.event_name == event.eventIdentifier)
      ?.ios_constant_name ?? `"${event.eventIdentifier}"`
  }
${
  event.parameters.length
    ? `             withValues:@{
  ${event.parameters.map((param) => {
    return `                     ${
      iosParameter.find(
        (prm) => prm.event_parameter_name == param.parameterIdentifier
      )?.ios_constant_name ?? `"${param.parameterIdentifier}"`
    }: @<<PLACE_HOLDRER_FOR_PARAM_VALUE>>`;
  }).join(`
  `)}
                       }`
    : `{}
`
}];`;
}).join(`

`);
    if (isResponse == "No") {
      setEvgenInappCodePrimary(evgenInappCodeSwift);
      setEvgenInappCodeSecondary(evgenInappCodeObjC);
    } else {
      const evgenInappCodeWListenerSwift = evgenInappCodeSwift
        .split(
          `   ]
  );`
        )
        .join(
          `   ],
    completionHandler: { (response: [String : Any]?, error: Error?) in
      if let response = response {
        print("In app event callback Success: ", response)
      }
      if let error = error {
        print("In app event callback ERROR:", error)
      }
    }
);`
        );
        const evgenInappCodeWListenerObjC = evgenInappCodeObjC
        .split(
          `withValues:@{`
        )
        .join(
          `eventValues:@{`
        ).split(`}];`).join(
          `  completionHandler:^(NSDictionary *response, NSError *error) {
                                   if (response) {
                                       NSLog(@"In app event callback Success: %@", response);
                                   }
                                   if (error) {
                                       NSLog(@"In app event callback ERROR: %@", error);
                                   }
                               }];`
        );
      setEvgenInappCodePrimary(evgenInappCodeWListenerSwift);
      setEvgenInappCodeSecondary(evgenInappCodeWListenerObjC)
    }
  };

  useEffect(() => {
    if (generatedEventCodePrimary) {
      setInappCodePrimary(generatedEventCodePrimary);
      setInappCodeSecondary(generatedEventCodeSecondary);
      if (location.state.os === OSType.Android) {
        setInappCodeWListenerPrimary(`import com.appsflyer.AppsFlyerLib;
import com.appsflyer.AFInAppEventType; // Predefined event names
import com.appsflyer.AFInAppEventParameterName; // Predefined parameter names

${generatedEventCodePrimary}`);
        setInappCodeWListenerSecondary(`import com.appsflyer.AppsFlyerLib
import com.appsflyer.AFInAppEventType // Predefined event names
import com.appsflyer.AFInAppEventParameterName // Predefined parameter names

  ${setGeneratedEventCodeSecondary}`);
      } else {
        setInappCodeWListenerPrimary(generatedEventCodePrimary);
        setInappCodeWListenerSecondary(generatedEventCodeSecondary);
      }
    }
  }, [generatedEventCodePrimary, generatedEventCodeSecondary]);

  const [evgenEventsObject, setEvgenEventsObject] =
    useState<EvgenEventsObject>();

  const handleFileUpload = useCallback((newFile, target) => {
    if (newFile) {
      const fileReader = new FileReader();
      fileReader.readAsText(newFile, "UTF-8");
      fileReader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result.toString());
          if (json?.events) {
            setEvgenEventsObject(json);
            if (location.state.os === OSType.Android) {
              updateEventCodeEvgenAndroid("No", json?.events);
            } else {
              updateEventCodeEvgenIOS("No", json?.events);
            }
          } else {
            console.error("JSON does not match the EVGEN output structure");
          }
        } catch (error) {
          console.error("JSON parse error: ", error);
        }
      };
    }
  }, []);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const stepsAndroid = [
    {
      label: t("ip_AppEvents_android_stepOneHeading"),
      component: (
        <>
          <Typography>
            {t("ip_AppEvents_android_stepOneDescription")}
            <AmplitudeLink
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/android-sdk-reference-appsflyerlib`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <code>{t("ip_AppEvents_android_appsFlyerLibLink")}</code>
            </AmplitudeLink>
          </Typography>

          <DJCodeBlock {...codeProps}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("ip_AppEvents_android_stepTwoHeading"),
      component: (
        <>
          <Typography>
            {t("ip_AppEvents_android_stepTwoDescription")}
          </Typography>
          <DJCodeBlock {...codeProps2}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  const stepsIOS = [
    {
      label: t("ip_SendInApp_ios_importAppsFlyerLib"),
      component: (
        <>
          <Typography>
            {t("ip_SendInApp_ios_stepOneDescription")}
            <AmplitudeLink
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/ios-sdk-reference-appsflyerlib`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("ip_SendInApp_ios_linkText")}
            </AmplitudeLink>
          </Typography>
          {/* Step 1: Import AppsFlyerLib */}
          <DJCodeBlock
            codePrimary={`import AppsFlyerLib`}
            codeSecondary={`#import <AppsFlyerLib/AppsFlyerLib.h>`}
            showLineNumbers={true}
          />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  const steps = location.state.os === OSType.Android ? stepsAndroid : stepsIOS;

  steps.push(
    {
      label: t("ip_SendInApp_android_designInApp"),
      component: (
        <>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="InApp Events Tabs Choose Type"
          >
            <Tab label={t("ip_AppEvents_Evgen_upload")} {...a11yProps(0)} />
            <Tab label={t("ip_AppEvents_Evgen_single")} {...a11yProps(1)} />
          </Tabs>
          <CustomTabPanel value={tabValue} index={0}>
            <Typography variant="h3" sx={{ fontWeight: "400 !important" }}>
              AppsFlyer{" "}
              <AmplitudeLink href="https://evgen.appsflyer.com/">
                In-app event generator
              </AmplitudeLink>
            </Typography>
            <br />
            <DragAndDropUpload
              id="1"
              label={t("ip_AppEvents_Evgen_upload")}
              accept=".json"
              helperText={t("ipAppEvents_Evgen_jsononly")}
              onFileUpload={handleFileUpload}
            />

            {evgenEventsObject?.events?.length ? (
              <Box maxHeight={400} overflow={"auto"}>
                <EventsTable {...evgenEventsObject} />
              </Box>
            ) : null}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            {/* Single InApp */}
            <Box>
              <Box>
                <Typography variant="h3">
                  {t("ip_SendInApp_android_stepTwoHeading")}
                </Typography>
                <RadioGroup
                  row
                  value={selectedRadioOne}
                  onChange={handleRadioChangeOne}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={t("ip_SendInApp_android_predefinedLabel")}
                    value="Predefined"
                  />
                  <Select
                    id="event-name-1"
                    variant="outlined"
                    defaultValue={t("ip_SendInApp_ios_chooseOption")}
                    placeholder={t("ip_SendInApp_ios_chooseOption")}
                    value={chosenEventName}
                    onChange={setChosenEventName}
                    disabled={isCustomOne}
                    options={options}
                    size={"medium"}
                  ></Select>
                  <FormControlLabel
                    sx={{ mx: 2 }}
                    control={<Radio />}
                    label={t("ip_SendInApp_android_customLabel")}
                    value={"custom"}
                  />
                  <TextField
                    variant="outlined"
                    id="custom1"
                    value={customEventName}
                    onChange={(event) => setCustomEventName(event.target.value)}
                    disabled={!isCustomOne}
                  />
                </RadioGroup>
              </Box>
              <Box>
                <Typography variant="h3">
                  {t("ip_SendInApp_android_stepThreeHeading")}
                </Typography>
                <SendInappEventParamForm
                  eventParam={eventParam1}
                  onEventParamChange={(value) => setEventParam1(value)}
                />
                {paramCount > 1 && (
                  <SendInappEventParamForm
                    eventParam={eventParam2}
                    onEventParamChange={(value) => setEventParam2(value)}
                  />
                )}
                {paramCount > 2 && (
                  <SendInappEventParamForm
                    eventParam={eventParam3}
                    onEventParamChange={(value) => setEventParam3(value)}
                  />
                )}
                {paramCount > 3 && (
                  <SendInappEventParamForm
                    eventParam={eventParam4}
                    onEventParamChange={(value) => setEventParam4(value)}
                  />
                )}
                {paramCount > 4 && (
                  <SendInappEventParamForm
                    eventParam={eventParam5}
                    onEventParamChange={(value) => setEventParam5(value)}
                  />
                )}
                {paramCount < 5 && (
                  <Button
                    onClick={handleAddParameter}
                    fullWidth
                    variant="outlined"
                    size="large"
                    style={{ marginTop: "15px" }}
                  >
                    {t("ip_SendInApp_ios_addParamButton")}
                  </Button>
                )}
                {paramCount > 1 && (
                  <Button
                    onClick={handleRemoveParameter}
                    fullWidth
                    variant="outlined"
                    size="large"
                    color={"error"}
                    style={{ marginTop: "15px" }}
                  >
                    {t("ip_SendInApp_ios_removeParamButton")}
                  </Button>
                )}
              </Box>
              <Box>
                <br />
                <Typography variant="h2">
                  {t("ip_SendInApp_android_generatedEventCodeHeading")}
                </Typography>

                {/* Step 2: Generated event code */}
                <DJCodeBlock
                  codePrimary={generatedEventCodePrimary}
                  codeSecondary={generatedEventCodeSecondary}
                  showLineNumbers={true}
                />
              </Box>
            </Box>
          </CustomTabPanel>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("ip_AppEventInApp_android_stepFiveHeading"),
      component: (
        <>
          <Typography>
            {t("ip_AppEventInApp_android_logEventDescription")}
          </Typography>
          <Box mt={2}>
            <Typography variant="body1">
              {t("ip_AppEventInApp_android_responseListenerLabel")}
              <ExplanationTooltip />
            </Typography>
            <RadioGroup
              row
              value={responseListRequired}
              onChange={handleResponseChange}
            >
              <FormControlLabel
                label={t("ip_AppEventInApp_android_noLabel")}
                control={<Radio checked={!responseListenerRequired} />}
                onChange={() => setResponseListenerRequired(false)}
                value="No"
              />
              <FormControlLabel
                label={t("ip_AppEventInApp_android_yesLabel")}
                control={<Radio checked={responseListenerRequired} />}
                onChange={() => setResponseListenerRequired(true)}
                value="Yes"
              />
            </RadioGroup>
          </Box>
          {tabValue == 1 && (
            <DJCodeBlock
              codePrimary={inappCodeWListenerPrimary}
              codeSecondary={inappCodeWListenerSecondary}
              showLineNumbers={true}
            />
          )}
          {tabValue == 0 && (
            <>
              {evgenEventsObject?.events?.length && (
                <MultiSelectControl
                  options={evgenEventsObject.events.map((event) => {
                    return {
                      value: event.eventIdentifier,
                      label: event.eventName,
                    };
                  })}
                  onSelectedEventsChange={(selectedValues) =>
                    filterEvgenEvents(selectedValues)
                  }
                ></MultiSelectControl>
              )}
              <DJCodeBlock
                codePrimary={evgenInappCodeJava}
                codeSecondary={evgenInappCodeKotlin}
                showLineNumbers={true}
              />
            </>
          )}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    }
  );

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Typography sx={{ mb: 2 }} variant="h1">
          {t("ip_SendInApp_android_sendInAppEventHeading")}
        </Typography>
        <Stepper
          activeStep={activeStep}
          style={{ border: "none" }}
          orientation="vertical"
        >
          {steps.map(({ label, component, shouldDisplayNavigationButtons }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {component}
                {shouldDisplayNavigationButtons && (
                  <Box sx={{ mt: 2 }}>
                    {activeStep !== 0 && (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleBack}
                      >
                        {t("general_Back")}
                      </Button>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={
                          (location.state.os === OSType.Android &&
                            activeStep === 2 &&
                            ((tabValue == 1 &&
                              ((!isCustomOne && !chosenEventName) ||
                                (isCustomOne && !customEventName) ||
                                !eventParam1)) ||
                              (tabValue == 0 && !evgenEventsObject))) ||
                          (location.state.os === OSType.iOS &&
                            activeStep === 1 &&
                            ((tabValue == 1 &&
                              ((!isCustomOne && !chosenEventName) ||
                                (isCustomOne && !customEventName) ||
                                !eventParam1)) ||
                              (tabValue == 0 && !evgenEventsObject)))
                        }
                      >
                        {activeStep === steps.length - 1
                          ? t("general_Finish")
                          : t("general_Next")}
                      </Button>
                    )}
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          disabled={activeStep != steps.length - 1}
          onClick={() => handleClick()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(SendInapp);
