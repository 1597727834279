import React from "react";
import { UploadSingleFile } from "@appsflyer/fe-ui-core";
import { Box } from "@mui/material";

interface DragAndDropUploadProps {
  id: string;
  label: string;
  accept: string;
  helperText?: string;
  onFileUpload: (newFile: File, target?) => void; 
}

const DragAndDropUpload: React.FC<DragAndDropUploadProps> = ({
  id,
  label,
  accept,
  helperText,
  onFileUpload,
}) => {

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      onFileUpload(file); 
      event.dataTransfer.clearData();
    }
  };

  const handleFileInputChange = (newFile: File, target) => {
    if (newFile) {
      onFileUpload(newFile, target); 
    }
  };

  return (
    <Box
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <UploadSingleFile
        id={id}
        label={label}
        accept={accept}
        helperText={helperText}
        onChange={handleFileInputChange}
      />
    </Box>
  );
};

export default DragAndDropUpload;
