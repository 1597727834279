import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";
import { CopyBlock, a11yDark } from "react-code-blocks";
import { ReactComponent as CustomIcon } from "./code-alt-svgrepo-com.svg";
import "./codeBlockStyle.css";
import { amplitudeTrack_tabClick } from "../../services/utils";
import { ISessionProps } from "./IProgress";

export interface ICodeBlock {
  codePrimary: string;
  codeSecondary?: string;
  language?: string;
  highlight?: string;
  highlightSecondary?: string;
  showLineNumbers: boolean;
  wrapLongLines?: boolean;
  scrollPosition?: "top" | "bottom" | number;
}

const DJCodeBlock: React.FC<ICodeBlock> = ({
  codePrimary,
  codeSecondary,
  highlight: highlight,
  highlightSecondary,
  showLineNumbers,
  wrapLongLines,
  scrollPosition = "top",
}) => {
  const location = useLocation();
  const selectedOS = location.state?.os;

  // Tabs based on the selected OS
  const tabs =
    selectedOS === "android" ? ["Java", "Kotlin"] : ["Swift", "Objective-C"];

  const savedTabKey = "DJCodeBlock_selectedTab";
  const [selectedTab, setSelectedTab] = useState<string>(
    () => localStorage.getItem(savedTabKey) || tabs[0]
  );
  const codeContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    localStorage.setItem(savedTabKey, newValue);

    // Increment click count and track the event in Amplitude
    amplitudeTrack_tabClick(location.state, newValue); // Amplitude tracking
    sendTabClicksToBackend(); // Send to backend
  };

  const sendTabClicksToBackend = async () => {
    const newState: ISessionProps = {
      ...location.state,
      tabClick: true
    }
    navigate(location.pathname, {
      replace: true,
      state: newState,
    });
  };

  const sanitizeLanguage = (language: string) =>
    language.toLowerCase().replace(/[^a-z]/g, "");

  useEffect(() => {
    if (codeContainerRef.current) {
      const container = codeContainerRef.current;
      if (scrollPosition === "top") {
        container.scrollTop = 0;
      } else if (scrollPosition === "bottom") {
        container.scrollTop = container.scrollHeight;
      } else if (typeof scrollPosition === "number") {
        container.scrollTop = scrollPosition;
      }
    }
  }, [scrollPosition]);

  useEffect(() => {
    // Ensure the selected tab is valid for the current OS
    if (!tabs.includes(selectedTab)) {
      const defaultTab = tabs[0];
      setSelectedTab(defaultTab);
      localStorage.setItem(savedTabKey, defaultTab);
    }
  }, [tabs, selectedTab]);

  return (
    <div>
      {/* Tabs for language selection */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="code language tabs"
        sx={{
          marginBottom: "10px",
          borderBottom: "1px solid #e6e9f0",
          maxWidth: "700px",
          "& .MuiTabs-flexContainer": { borderBottom: "0px", height: "55px" },
        }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab}
            label={tab}
            value={tab}
            icon={
              <CustomIcon
                style={{
                  color: selectedTab === tab ? "#036DEB" : undefined, // Example of dynamic stroke color
                }}
              />
            }
            iconPosition="start"
            sx={{
              fontSize: "14px",
              padding: "8px 16px 0px",
              margin: "0px",
              "&:hover": {
                "& .MuiTab-iconWrapper": {
                  color: "#036DEB", // Hover color blue for the CustomIcon
                },
              },
            }}
          />
        ))}
      </Tabs>

      {/* Render the selected tab's code snippet */}
      <div
        className="code-block-container"
        ref={codeContainerRef} // Attach the ref here
        style={{
          overflowY: "auto",
          maxHeight: "26vh", // Ensure scrollable container
          maxWidth: "700px",
          borderRadius: "9px",
        }}
      >
        <CopyBlock
          customStyle={{
            overflowX: "auto",
            minHeight: "40px",
            maxWidth: "700px",
          }}
          text={selectedTab == tabs[0] ? codePrimary : codeSecondary}
          language={sanitizeLanguage(selectedTab)}
          showLineNumbers={showLineNumbers}
          highlight={selectedTab === tabs[0] ? highlight : highlightSecondary}
          theme={a11yDark}
          wrapLongLines={wrapLongLines}
        />
      </div>
    </div>
  );
};

const DJSimpleCodeBlock: React.FC<ICodeBlock> = (props) => {
  return (
    <div className="code-block-container">
      <CopyBlock
        customStyle={{
          overflow: "auto",
          minHeight: "40px",
          maxHeight: "42vh",
          maxWidth: "700px",
          borderRadius: "10px",
        }}
        text={props.codePrimary}
        language={props.language}
        showLineNumbers={props.showLineNumbers}
        highlight={props.highlight}
        theme={a11yDark}
        wrapLongLines={props.wrapLongLines}
      />
    </div>
  );
};

export { DJCodeBlock, DJSimpleCodeBlock };
