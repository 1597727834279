import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Box,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { DJCodeBlock } from "../../main/components/codeblock";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IProgress,
  ISessionProps,
  SessionStatus,
} from "../../main/components/IProgress";
import WarningIcon from "@mui/icons-material/Warning";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { Typography, Note } from "@appsflyer/fe-ui-core";
import transition from "../../main/components/transition";
import ExplanationTooltip from "../../main/components/ExplanationTooltip";
import {
  amplitudeTrack_continue,
  amplitudeTrack_inputChange,
  InputType,
  sendGtagOnce,
} from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";

const StartSDKAndroid = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [devKey, setDevKey] = useState();
  const [responseListenerRequired, setResponseListenerRequired] =
    useState(false);
  const [gcdRequired, setGCDRequired] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    const lsDevKey = localStorage.getItem("devKey");
    if (lsDevKey) {
      setDevKey(JSON.parse(lsDevKey));
    }

    if (
      location.state?.progress.find((prog) => prog.id === "startsdk_setgcd")
    ) {
      setGCDRequired(
        location.state?.progress.find((prog) => prog.id === "startsdk_setgcd")
          .isListener
      );
    }
  }, []);

  const handleClick = () => {
    const progressArr: IProgress[] = location.state?.progress.slice();
    progressArr[progressArr.length - 1].isListener = responseListenerRequired;

    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      sessionStatus: SessionStatus.ImplementedNotTested,
      currentPage: location.state?.currentPage + 1,
    };

    // send gtag for implemented not test
    sendGtagOnce(
      "implemented_not_tested",
      "StartSDKAndroid",
      location.state.sessionId
    );

    amplitudeTrack_continue(location.state, { responseListenerRequired });

    navigate(`/startsdk/${location.state.os.toLowerCase()}/testsdktype`, {
      state: progressState,
    });
  };

  const codeJava_Step1 = `import com.appsflyer.AppsFlyerLib;`;
  const codeJava_Step2 = `AppsFlyerLib.getInstance().init("${devKey}", ${
    gcdRequired ? "conversionListener" : "null"
  }, this);`;
  const codeJava_Step3 = `AppsFlyerLib.getInstance().setDebugLog(true);`;

  const codeKotlin_Step1 = `import com.appsflyer.AppsFlyerLib`;
  const codeKotlin_Step2 = `AppsFlyerLib.getInstance().init("${devKey}", null, this)`;
  const codeKotlin_Step3 = `AppsFlyerLib.getInstance().setDebugLog(true)`;

  const codeBlockArray = [
    {
      codePrimary: codeJava_Step1,
      codeSecondary: codeKotlin_Step1,
      showLineNumbers: false,
    },
    {
      codePrimary: codeJava_Step2,
      codeSecondary: codeKotlin_Step2,
      showLineNumbers: false,
    },
    {
      codePrimary: codeJava_Step3,
      codeSecondary: codeKotlin_Step3,
      showLineNumbers: false,
    },
  ];

  const codeJava_Step4A = `AppsFlyerLib.getInstance().start(this);`;

  const codeJava_Step4B = `AppsFlyerLib.getInstance().start(getApplicationContext(), "", new AppsFlyerRequestListener() {
  @Override
  public void onSuccess() {
    // YOUR CODE UPON SUCCESS
  }
  
  @Override
  public void onError(int i, @NonNull String s) {
    // YOUR CODE FOR ERROR HANDLING
  }
});`;

  const codeKotlin_Step4A = `AppsFlyerLib.getInstance().start(this)`;
  const codeKotlin_Step4B = `AppsFlyerLib.getInstance().start(this,${devKey}, object :
    AppsFlyerRequestListener {
        
        //Success callback
        override fun onSuccess() {
            Log.d(LOG_TAG, "Launch sent successfully")
        }

        //Error callback
        override fun onError(errorCode: Int, errorDesc: String) {
            Log.d(LOG_TAG, "Launch failed to be sent:\\n" +
                    "Error code: " + errorCode + "\\n"
                    + "Error description: " + errorDesc)
        }
    })`;

  const codeBlockArray_step4 = [
    {
      codePrimary: codeJava_Step4A,
      codeSecondary: codeKotlin_Step4A,
      showLineNumbers: false,
    },
    {
      codePrimary: codeJava_Step4B,
      codeSecondary: codeKotlin_Step4B,
      showLineNumbers: true,
    },
  ];

  const steps = [
    {
      label: t("startSDK_StartSDK_android_Step1"),
      component: (
        <>
          <Typography>
            {t("startSDK_StartSDK_android_ImportPart1")}{" "}
            <AmplitudeLink
              target="_blank"
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/android-sdk-reference-appsflyerlib`}
            >
              <code>AppsFlyerLib</code>
            </AmplitudeLink>
            :
          </Typography>

          <DJCodeBlock {...codeBlockArray[0]}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("startSDK_StartSDK_android_Step2"),
      component: (
        <>
          <Box display={"flex"}>
            <Typography mr={1}>
              {t("startSDK_StartSDK_android_InitializePart1")}{" "}
              <AmplitudeLink
                target="_blank"
                href={`https://${
                  location.state.lang != "en" ? location.state.lang + "." : ""
                }dev.appsflyer.com/hc/docs/android-sdk-reference-appsflyerlib#init`}
              >
                <code>init</code>{" "}
              </AmplitudeLink>
              {t("startSDK_StartSDK_android_InitializePart2")}
            </Typography>
          </Box>

          <DJCodeBlock {...codeBlockArray[1]}></DJCodeBlock>
          <Box mt={2}>
            <Note>
              {t("startSDK_StartSDK_android_InitializePart2_dk")}
              <code>DevKey</code>
              {t("startSDK_StartSDK_android_InitializePart2_dk2")}
              <code>init</code>
              {t("startSDK_StartSDK_android_InitializePart2_dk3")}
            </Note>
          </Box>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("startSDK_StartSDK_android_Step3"),
      component: (
        <>
          <Box display={"flex"} alignItems={"center"}>
            <Typography>
              {t("startSDK_StartSDK_android_SeeDebugLogs")}
              <code>setDebugLog</code>
              {t("startSDK_StartSDK_android_SeeDebugLogs_b")}
              <code>init()</code>
              {t("startSDK_StartSDK_android_SeeDebugLogs_c")}
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"} mt={2}>
            <WarningIcon style={{ fontSize: 20, marginRight: 5 }} />
            <Typography variant="h3">
              {t("startSDK_StartSDK_android_Warning")}
            </Typography>
          </Box>
          <Typography >
            {t("startSDK_StartSDK_android_WarningMessage")}
          </Typography>

          <DJCodeBlock {...codeBlockArray[2]}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("startSDK_Step4_android_Step4"),
      component: (
        <>
          <Typography>
            {t("startSDK_Step4_android_Step4DescriptionPart1")}
            <AmplitudeLink
              ml={0.5}
              target="_blank"
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/android-sdk-reference-appsflyerlib#start`}
            >
              {t("startSDK_Step4_android_StartLink")}
            </AmplitudeLink>{" "}
            {t("startSDK_Step4_android_Step4DescriptionPart2")}
          </Typography>

          <Box display={"flex"} alignItems={"center"} mt={2}>
            <Typography>
              {t("startSDK_Step4_android_ResponseListenerRequired")}
              <ExplanationTooltip />
            </Typography>
            <RadioGroup
              style={{ marginLeft: "50px" }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue="No"
            >
              <FormControlLabel
                onChange={() => {
                  setResponseListenerRequired(false);
                  amplitudeTrack_inputChange(
                    location.state,
                    InputType.Radio,
                    "responseListenerRequired",
                    false
                  );
                }}
                value="No"
                control={<Radio checked={!responseListenerRequired} />}
                label={t("startSDK_Step4_android_No")}
              />
              <FormControlLabel
                onChange={() => {
                  setResponseListenerRequired(true);
                  amplitudeTrack_inputChange(
                    location.state,
                    InputType.Radio,
                    "responseListenerRequired",
                    true
                  );
                }}
                value="Yes"
                control={<Radio checked={responseListenerRequired} />}
                label={t("startSDK_Step4_android_Yes")}
              />
            </RadioGroup>
          </Box>
          <Box mt={-1}>
            {responseListenerRequired ? (
              <DJCodeBlock {...codeBlockArray_step4[1]}></DJCodeBlock>
            ) : (
              <DJCodeBlock {...codeBlockArray_step4[0]}></DJCodeBlock>
            )}
          </Box>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Typography variant="h1">
          {t("startSDK_StartSDK_android_StartSDK")}
        </Typography>
        <Stepper
          activeStep={activeStep}
          style={{ border: "none" }}
          orientation="vertical"
        >
          {steps.map(({ label, component, shouldDisplayNavigationButtons }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {component}
                {shouldDisplayNavigationButtons && (
                  <Box sx={{ mt: 2 }}>
                    {activeStep !== 0 && (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleBack}
                        // sx={{ mt: 2, mr: 1 }}
                      >
                        {t("general_Back")}
                      </Button>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        // sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1
                          ? t("general_Finish")
                          : t("general_Next")}
                      </Button>
                    )}
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(StartSDKAndroid);
