import { useState, useEffect, ChangeEvent } from "react";
import {
  Button,
  Box,
  Container,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IProgress,
  ISessionProps,
  SessionStatus,
} from "../../main/components/IProgress";
import { DJCodeBlock, ICodeBlock } from "../../main/components/codeblock";
import { Typography, TextField } from "@appsflyer/fe-ui-core";
import transition from "../../main/components/transition";
import ExplanationTooltip, {
  TooltipType,
} from "../../main/components/ExplanationTooltip";
import {
  amplitudeTrack_continue,
  amplitudeTrack_inputChange,
  InputType,
  sendGtagOnce,
} from "../../services/utils";

const StartSDKiOS = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [devKey, setDevKey] = useState();
  const [appId, setAppId] = useState<string>("");
  const [gcdRequired, setGCDRequired] = useState(false);
  const [timeoutInterval, setTimeoutInterval] = useState("60");

  useEffect(() => {
    if (location.state) {
      const state: ISessionProps = location.state as ISessionProps;
      setAppId(state?.appID ?? "");

      const lsDevKey = localStorage.getItem("devKey");
      if (lsDevKey) {
        setDevKey(JSON.parse(lsDevKey));
      }

      if (
        location.state?.progress.find((prog) => prog.id === "startsdk_setgcd")
      ) {
        setGCDRequired(
          location.state?.progress.find((prog) => prog.id === "startsdk_setgcd")
            .isListener
        );
      }
    }
  }, [location.state]);

  const handleClick = () => {
    const progressArr: IProgress[] = location.state?.progress.slice();
    progressArr[progressArr.length - 1].isListener = gcdRequired;

    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      sessionStatus: SessionStatus.ImplementedNotTested,
      currentPage: location.state?.currentPage + 1,
    };

    // send gtag for implemented not test
    sendGtagOnce(
      "implemented_not_tested",
      "StartSDKiOS",
      location.state.sessionId
    );

    amplitudeTrack_continue(location.state, {
      responseListenerRequired: isResponseListener,
    });

    navigate(`/startsdk/${location.state?.os}/testsdktype`, {
      state: progressState,
    });
  };

  const [isDebug, setIsDebug] = useState("true");
  const [isResponseListener, setIsResponseListener] = useState("false");
  const [isDelegate, setIsDelegate] = useState("false");
  const [isATT, setIsATT] = useState("false");
  const [codePrimary, setCodePrimary] = useState("");
  const [codeSecondary, setCodeSecondary] = useState("");

  useEffect(() => {
    setCodePrimary(`import UIKit
import AppsFlyerLib
      
@UIApplicationMain
class AppDelegate: UIResponder, UIApplicationDelegate {     
          
    func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: 
    [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
        // set the App ID & the DevKey
        AppsFlyerLib.shared().appsFlyerDevKey = "${devKey}"
        AppsFlyerLib.shared().appleAppID = "${appId}"
        ${
          isDebug === "true"
            ? `
        // Debug logs
        AppsFlyerLib.shared().isDebug = true
`
            : ``
        }${
      isATT === "true"
        ? `
        // Only for ATT
        AppsFlyerLib.shared().waitForATTUserAuthorization(timeoutInterval: ${timeoutInterval})`
        : ""
    }${
      isDelegate === "true"
        ? `
        // For Scene Delegate
        NotificationCenter.default.addObserver(self, selector: #selector(didBecomeActiveNotification),
        name: UIApplication.didBecomeActiveNotification, 
        object: nil)`
        : ""
    }
    }

    ${
      isDelegate === "true"
        ? `// For Scene Delegate
    @objc func didBecomeActiveNotification() {`
        : `// For App Delegate
    func applicationDidBecomeActive(_ application: UIApplication) {`
    }        
    ${
          isResponseListener === "true"
            ? `// With Listener
        AppsFlyerLib.shared().start(completionHandler: { (dictionary, error) in
          if (error != nil){
              print(error ?? "")
              return
          } else {
              print(dictionary ?? "")
              return
          }
        })` : `// No Listener
        AppsFlyerLib.shared().start()`
        }
        ${
          isATT === "true"
            ? `
        // Only for ATT
        if #available(iOS 14, *) {
          ATTrackingManager.requestTrackingAuthorization { (status) in
            switch status {
            case .denied:
                print("AuthorizationStatus is denied")
            case .notDetermined:
                print("AuthorizationStatus is notDetermined")
            case .restricted:
                print("AuthorizationStatus is restricted")
            case .authorized:
                print("AuthorizationStatus is authorized")
            @unknown default:
                fatalError("Invalid authorization status")
            }
          }
        }`
            : ``
        }
    }
}`);

    setCodeSecondary(`#import <UIKit/UIKit.h>
#import <AppsFlyerLib/AppsFlyerLib.h>

@interface AppDelegate ()

@end

@implementation AppDelegate

@synthesize conversionData;

- (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {
  ${
    isDebug === "true"
      ? `
    [AppsFlyerLib shared].isDebug = YES;
`
      : ``
  }
    [AppsFlyerLib shared].appsFlyerDevKey = @"${devKey}";
    [AppsFlyerLib shared].appleAppID = @"${devKey}E";
    ${
      isATT === "true"
        ? `
    [[AppsFlyerLib shared] waitForATTUserAuthorizationWithTimeoutInterval:${timeoutInterval}];
    `   : ""
    }${isDelegate === "true"
        ? `
    // For Scene Delegate
    // Subscribe to didBecomeActiveNotification if you use SceneDelegate or just call
    // -[AppsFlyerLib start] from -[AppDelegate applicationDidBecomeActive:]
    [[NSNotificationCenter defaultCenter] addObserver:self
                                          selector:@selector(didBecomeActiveNotification)
                                          name:UIApplicationDidBecomeActiveNotification
                                          object:nil];
                                          ` : ``}
    return YES;
}
    
${isDelegate === "true"
    ? `- (void)didBecomeActiveNotification {`
    : `- (void)applicationDidBecomeActive:(UIApplication *)application {
    `
}${
      isResponseListener === "true"
            ? `    [[AppsFlyerLib shared] startWithCompletionHandler:^(NSDictionary<NSString *,id> *dictionary, NSError *error) {
            if (error) {
                NSLog(@"%@", error);
                return;
            }
            if (dictionary) {
                NSLog(@"%@", dictionary);
                return;
            }
        }];`
            : `    [[AppsFlyerLib shared] start];`
        }${
        isATT === "true"
            ? `
   
    if (@available(iOS 14, *)) {
        [ATTrackingManager requestTrackingAuthorizationWithCompletionHandler:^(ATTrackingManagerAuthorizationStatus status) {
            switch (status) {
                case ATTrackingManagerAuthorizationStatusDenied:
                    NSLog(@"AuthorizationSatus is denied");
                    break;
                case ATTrackingManagerAuthorizationStatusNotDetermined:
                    NSLog(@"AuthorizationSatus is notDetermined");
                    break;
                case ATTrackingManagerAuthorizationStatusRestricted:
                    NSLog(@"AuthorizationSatus is restricted");
                    break;
                case ATTrackingManagerAuthorizationStatusAuthorized:
                    NSLog(@"AuthorizationSatus is authorized");
                    break;
                default:
                    NSLog(@"Invalid authorization status");
                    break;
            }
        }];
    }`
            : ``
        }
}`);
  }, [
    isDebug,
    isDelegate,
    isATT,
    isResponseListener,
    timeoutInterval,
    devKey,
    appId,
  ]);

  const setCodeBlock = () => {};

  const codeProps: ICodeBlock = {
    codePrimary: codePrimary,
    codeSecondary: codeSecondary,
    language: "swift",
    showLineNumbers: true,
  };

  const handleResponseListenerChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setIsResponseListener(value);
    amplitudeTrack_inputChange(
      location.state,
      InputType.Radio,
      "responseListenerRequired",
      event.target.value
    );
    setCodeBlock();
  };

  const handleIsDebugChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setIsDebug(value);
    amplitudeTrack_inputChange(
      location.state,
      InputType.Radio,
      "isDebug",
      event.target.value
    );
    setCodeBlock();
  };

  const handleIsDelegateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setIsDelegate(value);
    amplitudeTrack_inputChange(
      location.state,
      InputType.Radio,
      "SceneDelegate",
      event.target.value
    );
    setCodeBlock();
  };

  const handleIsATTChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setIsATT(value);
    amplitudeTrack_inputChange(
      location.state,
      InputType.Radio,
      "ATT",
      event.target.value
    );
    setCodeBlock();
  };

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Typography variant="h1">{t("start_sdk")}</Typography>
        <>
          <Box display={"flex"} gap={5} mt={1} mb={-1} >
            <Box display={"flex"} gap={5}>
              {/* Debug */}
              <Box my={2}>
                <Typography>
                  {t("testConsole_TestType_manualTest_desc_c")}
                </Typography>
                <RadioGroup
                  aria-labelledby="response-listener-group-label"
                  value={isDebug}
                  onChange={handleIsDebugChange}
                  name="response-listener-group"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label={t("ip_AppEventStepFiveIos_ios_noLabel")}
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label={t("ip_AppEventInApp_android_yesLabel")}
                  />
                </RadioGroup>
              </Box>

              {/* Scene Delegate */}
              <Box my={2}>
                <Typography>
                  {t("StartSDK_startSDK_ios_step3_SceneDelegate")}
                </Typography>
                <RadioGroup
                  aria-labelledby="response-listener-group-label"
                  value={isDelegate}
                  onChange={handleIsDelegateChange}
                  name="response-listener-group"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label={t("ip_AppEventStepFiveIos_ios_noLabel")}
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label={t("ip_AppEventInApp_android_yesLabel")}
                  />
                </RadioGroup>
              </Box>

              {/* Listener */}
              <Box my={2}>
                <Typography>
                  {t("StartSDK_startSDKStep4_ios_response")}
                  <ExplanationTooltip />
                </Typography>
                <RadioGroup
                  aria-labelledby="response-listener-group-label"
                  value={isResponseListener}
                  onChange={handleResponseListenerChange}
                  name="response-listener-group"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label={t("ip_AppEventStepFiveIos_ios_noLabel")}
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label={t("ip_AppEventInApp_android_yesLabel")}
                  />
                </RadioGroup>
              </Box>

              {/* ATT */}
              <Box my={2}>
                <Typography>
                  {t("StartSDK_startSDK_ios_step3_ATT")}
                  <ExplanationTooltip tooltipType={TooltipType.ATT} />
                </Typography>
                <RadioGroup
                  aria-labelledby="response-listener-group-label"
                  value={isATT}
                  onChange={handleIsATTChange}
                  name="response-listener-group"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label={t("ip_AppEventStepFiveIos_ios_noLabel")}
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label={t("ip_AppEventInApp_android_yesLabel")}
                  />
                </RadioGroup>
                {isATT === "true" && (
                  <Box mt={1.5}>
                    <TextField
                      label={
                        <Typography>
                          {t("StartSDK_startSDK_ios_step3_ATT_interval")}
                          <ExplanationTooltip
                            tooltipType={TooltipType.ATT_Interval}
                          />
                        </Typography>
                      }
                      value={timeoutInterval}
                      type={"number"}
                      size={"s"}
                      onChange={(e) => {
                        setTimeoutInterval(e.currentTarget.value);
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <DJCodeBlock {...codeProps} />
        </>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(StartSDKiOS);
